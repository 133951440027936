import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { selectCurrentProfile, setCurrentProfile } from '../features/currentTool/currentProfileSlice';
import { setProfiles } from '../features/tools/toolSlice';
import { fetchTools } from '../features/tools/toolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faPencil, faTrashCan, faFloppyDisk, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import config from '../config/config';
import { addToast } from '../features/ui/uiSlice';
import { selectUser } from '../features/user/userSlice';

const ProfileManagerComponent = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const profiles = useSelector(state => state.tools.items.profiles);
    const currentProfile = useSelector(selectCurrentProfile);
    const apiURL = process.env.REACT_APP_API_URL;
    
    const [editMode, setEditMode] = useState(false);
    const [profileData, setProfileData] = useState({
        name: '',
        content: '',
    });

    useEffect(() => {
        if (currentProfile) {
            setProfileData({
                name: currentProfile.name,
                content: currentProfile.content,
            });
        } else {
            setProfileData({
                name: '',
                content: '',
            });
        }
    }, [currentProfile]);

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = async () => {
        try {
            if (currentProfile && currentProfile._id) {
                // Update existing profile
                await axios.put(`${apiURL}/profiles/${config.app_id}/${currentProfile._id}`, {
                    ...profileData,
                    lastUpdatedBy: user.username, // replace with actual user
                });
                dispatch(addToast(`Profile updated!`, 'success'));
                const updatedProfiles = profiles.map(profile => 
                    profile._id === currentProfile._id ? { ...profile, ...profileData, lastUpdatedAt: new Date().toISOString(), lastUpdatedBy: user.username } : profile
                );
                dispatch(setProfiles(updatedProfiles));
                dispatch(setCurrentProfile({ ...currentProfile, ...profileData, lastUpdatedAt: new Date().toISOString(), lastUpdatedBy: user.username }));
            } else {
                // Add new profile
                const response = await axios.post(`${apiURL}/profiles/${config.app_id}`, {
                    ...profileData,
                    createdBy: user.username, // replace with actual user
                });
                dispatch(setCurrentProfile(response.data));
                dispatch(setProfiles([...profiles, response.data]));
                dispatch(addToast(`Profile added!`, 'success'));
            }
            setEditMode(false);
            dispatch(fetchTools());
        } catch (error) {
            dispatch(addToast(`Error saving profile: ${error.message}`, 'error'));
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this profile?')) {
            try {
                if (!currentProfile || !currentProfile._id) {
                    throw new Error('No profile selected for deletion');
                }
                await axios.delete(`${apiURL}/profiles/${config.app_id}/${currentProfile._id}`);
                dispatch(setCurrentProfile(null));
                dispatch(setProfiles(profiles.filter(profile => profile._id !== currentProfile._id)));
                dispatch(addToast(`Profile deleted!`, 'success'));
                dispatch(fetchTools());
            } catch (error) {
                dispatch(addToast(`Error deleting profile: ${error.message}`, 'error'));
            }
        }
    };

    const handleAddNew = () => {
        dispatch(setCurrentProfile(null));
        setEditMode(true);
    };

    const handleSelectProfile = (profile) => {
        dispatch(setCurrentProfile(profile));
        setEditMode(false);
    };

    return (
        <div className="flex flex-col lg:flex-row overflow-auto slim-scrollbar h-40rem max-h-40rem">
            <div className="w-full lg:w-1/3 p-4 border-b lg:border-r border-gray-300">
                <div className="border-b border-primary mb-2 text-sm pb-2">
                    <h2 className="text-2xl font-bold text-poetsen text-primary">Profiles</h2>
                    Profiles are text records that can be used to store information about you, your business, or any other relevant information that you may need often. You can add the text saved in a profile directly to your chat threads to save time typing the same information over and over again.
                </div>
                {profiles.length === 0 ? (
                    <p>No Profiles Created</p>
                ) : (
                    profiles.map((profile) => (
                        <div
                            key={profile._id}
                            className={`p-2 rounded cursor-pointer ${currentProfile && currentProfile._id === profile._id ? 'bg-primary' : ''}`}
                            onClick={() => handleSelectProfile(profile)}
                        >
                            <FontAwesomeIcon icon={faFile} className='mr-2' />{profile.name}
                        </div>
                    ))
                )}
            </div>
            <div className="w-full lg:w-2/3 p-4">
                <div className="mb-4 flex space-x-2">
                    <button onClick={handleEdit} disabled={editMode || !currentProfile} className="px-4 py-2 bg-warning text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faPencil} className='mr-2' />Edit</button>
                    <button onClick={handleDelete} disabled={editMode || !currentProfile} className="px-4 py-2 bg-error text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faTrashCan} className='mr-2' />Delete</button>
                    <button onClick={handleAddNew} disabled={editMode} className="px-4 py-2 bg-success text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Add New</button>
                    <button onClick={handleSave} disabled={!editMode} className="px-4 py-2 bg-information text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faFloppyDisk} className='mr-2' />Save</button>
                </div>
                <div className="space-y-4">
                {!currentProfile && profiles.length === 0 && !editMode && (
                    <div className='text-poetsen'>
                        <p>Click the Add New button to create your first profile.</p>
                    </div>
                )}
                {!currentProfile && editMode && (
                    <>
                        <div>
                            <label className="block font-bold text-poetsen">Profile Name:</label>
                            <input
                                type="text"
                                value={profileData.name}
                                onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                                className="w-full text-black p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block font-bold text-poetsen">Content:</label>
                            <textarea
                                value={profileData.content}
                                onChange={(e) => setProfileData({ ...profileData, content: e.target.value })}
                                className="w-full text-black p-2 border rounded"
                            />
                        </div>
                        <button onClick={handleSave} disabled={!editMode} className="px-4 py-2 bg-information text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faFloppyDisk} className='mr-2' />Save</button>
                    </>
                )}
                {currentProfile && !editMode && (
                    <>
                        <div>
                            <label className="block font-bold">Profile Name:</label>
                            <p>{profileData.name}</p>
                        </div>
                        <div>
                            <label className="block font-bold">Content:</label>
                            <p>{profileData.content}</p>
                        </div>
                        <div>
                            <label className="block font-bold">Created At:</label>
                            <p>{currentProfile.createdAt}</p>
                        </div>
                        <div>
                            <label className="block font-bold">Created By:</label>
                            <p>{currentProfile.createdBy}</p>
                        </div>
                        <div>
                            <label className="block font-bold">Last Updated At:</label>
                            <p>{currentProfile.lastUpdatedAt}</p>
                        </div>
                        <div>
                            <label className="block font-bold">Last Updated By:</label>
                            <p>{currentProfile.lastUpdatedBy}</p>
                        </div>
                    </>
                )}
                {editMode && currentProfile && (
                    <>
                        <div>
                            <label className="block font-bold">Profile Name:</label>
                            <input
                                type="text"
                                value={profileData.name}
                                onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                                className="w-full text-black p-2 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block font-bold">Content:</label>
                            <textarea
                                value={profileData.content}
                                onChange={(e) => setProfileData({ ...profileData, content: e.target.value })}
                                className="w-full text-black p-2 border rounded"
                            />
                        </div>
                        <button onClick={handleSave} disabled={!editMode} className="px-4 py-2 bg-information text-white rounded disabled:opacity-50"><FontAwesomeIcon icon={faFloppyDisk} className='mr-2' />Save</button>
                    </>
                )}
                </div>
            </div>
        </div>
    );
};

export default ProfileManagerComponent;
