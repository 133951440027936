import React, {useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import config from '../config/config';
import CardListFavoriteTools from '../components/CardListFavoriteTools';
import DashboardProfilesCard from '../components/DashboardProfilesCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCloudArrowUp, faFeatherPointed, faFire, faGear, faImages, faPaperclip, faStore } from '@fortawesome/free-solid-svg-icons';
import { setCurrentTool } from '../features/currentTool/currentToolSlice';
import DashAddBoardItem from '../components/DashAddBoardItem';
import DashboardSuggestedPrompts from '../components/DashboardSuggestedPrompts';
import CurrentSubscriptionCard from '../components/CurrentSubscriptionCard';
import { fetchThreadHistory, setThreadHistory } from '../features/threads/threadHistorySlice';
import DashboardThreadHistoryCard from '../components/DashboardThreadHistoryCard';

function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(selectUser);
    const tools = useSelector((state) => [
        ...state.tools.items.tools,
        ...state.tools.items.assistants
    ].sort((a, b) => a.name.localeCompare(b.name)));
    const boards = useSelector(state => state.boards.boards);

    const handleSetCurrentTool = (toolId, route) => {
        const tool = tools.find(t => t._id === toolId);
        if (tool) {
            dispatch(setCurrentTool(tool));
            navigate(route);
        }
    };

    useEffect(() => {
        dispatch(fetchThreadHistory(user._id)).then((response) => {
          // Dispatch the setThreadHistory action with the fetched data
          if (response.type === 'threadHistory/fetchThreadHistory/fulfilled') {
            dispatch(setThreadHistory(response.payload));
          }
        });
      }, [dispatch, user]);

    return (
        <div className='flex flex-col items-center justify-center mt-4 w-11/12 mx-auto lg:mx-0 lg:w-full'>
            {user ? (<>
                <div className='flex flex-col lg:flex-row items-center justify-center w-full lg:w-4/5 lg:gap-4'>
                    <div className='text-poetsen flex flex-col lg:flex-row rounded w-full lg:w-2/3 mb-6 p-6 items-center bg-primary h-fit lg:h-64'>
                        <div className='lg:w-1/5 flex justify-center mb-3 lg:mb-0'>
                            <img src={user.pic} alt={user.name} className='w-24 h-24 rounded-full border-2 border-gray-900' />
                        </div>
                        <div className='flex flex-col w-full lg:w-3/5'>
                            <div className='text-3xl mb-2'>Welcome, {user.name ? user.name : user.username}!</div>
                            <div className='text-lg'>Email: {user.email}</div>
                            <div className='text-lg'>Username: {user.username}</div>
                            <div className='text-lg'>Credits: {Math.round(user?.ai_credits)}</div>
                        </div>
                        <div className='flex flex-col w-full lg:w-1/5'>
                            <div className='text-lg mt-2 lg:mt-0 lg:text-right'>It is currently:</div>
                            <div className='text-lg lg:text-right'>{new Date().toLocaleString('en-US')}</div>
                            <Link to="/settings" className='w-full'>
                                <button className='w-full border bg-primary hover:bg-gray-700 text-inverted hover:text-white font-bold py-2 px-4 rounded mt-4'>
                                    <FontAwesomeIcon icon={faGear} className='mr-2' />Edit Profile
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row lg:flex-wrap mb-6 w-full lg:w-1/3 h-fit lg:h-64'>
                        <Link to='/' className="flex flex-col justify-center gap-4 mt-0 text-poetsen lg:m-0 w-full lg:w-full border border-secondary text-xl lg:text-3xl text-center px-5 py-3 lg:py-6 lg:px-24 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded font-bold">
                            <img
                                src={config.logoUrl}
                                alt="Got to the Chat Space"
                                className={`w-16 lg:w-32 h-auto mx-auto rounded-full`}
                            />
                            Go to the Chat Space
                        </Link>
                    </div>
                </div>
                {/*<div className='flex flex-col lg:flex-row lg:flex-wrap lg:w-4/5 lg:justify-between'>
                    
                    <div className='flex flex-col text-lg lg:flex-row lg:flex-wrap mb-6 ml-4 lg:w-1/3 lg:justify-start lg:gap-4 text-poetsen'>
                        <button className='lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded' onClick={() => navigate('/boards')}>
                            <FontAwesomeIcon icon={faPaperclip} className='mr-2' />Boards
                        </button>
                        <button className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded" onClick={() => handleSetCurrentTool('664e91fb0e38b974f62d3cea', '/')}>
                            <FontAwesomeIcon icon={faFeatherPointed} className='mr-2' />Generate Blog Post
                        </button>
                        <button className="mt-2 lg:m-0 w-full lg:w-fit text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded" onClick={() => handleSetCurrentTool('664a99c4cc5bbe61ddc4c80e', '/')}>
                            <FontAwesomeIcon icon={faBuilding} className='mr-2' />GAIM Marketing Bot
                        </button>
                    </div>
                </div>*/}
                <div className="flex flex-col w-full lg:flex-row lg:flex-wrap lg:h-fit lg:w-4/5 gap-5 lg:gap-0 lg:gap-y-5">
                    {config.recent_threads_dash && <div className="w-full lg:w-1/3 rounded h-fit lg:h-96 lg:min-h-96 lg:px-2">
                        <DashboardThreadHistoryCard />
                    </div>}
                    {/*<div className="w-full lg:w-1/3 rounded h-fit lg:h-96 lg:min-h-96 lg:px-2">
                        <CardListFavoriteTools items={user.favorites?.generators} title='Your Favorite Tools' />
                    </div>*/}
                    {config.profiles && config.profile_dash && <div className="w-full lg:w-1/3 rounded h-fit lg:h-96 lg:min-h-96 lg:px-2">
                        <DashboardProfilesCard />
                    </div>}
                    {config.add_board_item_dash && <div className="w-full lg:w-1/3 rounded h-fit lg:h-96 lg:min-h-96 lg:px-2">
                        <DashAddBoardItem />
                    </div>}
                    <div className="w-full lg:w-1/3 rounded h-fit lg:h-96 lg:min-h-96 lg:px-2">
                        <CurrentSubscriptionCard />
                    </div>
                </div>
            </>) : (
                <p>No user is logged in.</p>
            )}
        </div>
    );
}

export default Dashboard;
