// src/components/Toast.js
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeToast } from '../features/ui/uiSlice';

const Toast = () => {
  const toasts = useSelector((state) => state.ui.toasts);
  const dispatch = useDispatch();

  useEffect(() => {
    toasts.forEach(toast => {
      setTimeout(() => dispatch(removeToast(toast.id)), 3000);
    });
  }, [toasts, dispatch]);

  return (
    <div className="fixed top-0 right-0 p-4 space-y-2 z-50">
      {toasts.map((toast) => (
        <div key={toast.id} className="bg-primary text-inverted p-3 rounded">
          {toast.message}
        </div>
      ))}
    </div>
  );
};

export default Toast;
