// components/BoardSelectorButton.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBoards, createBoard } from '../features/boards/boardSlice';
import { addBoardItem } from '../features/boards/boardItemSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

const BoardSelectorButton = ({ itemContent, itemType, userId }) => {
    const dispatch = useDispatch();
    const boards = useSelector(state => state.boards.boards);
    const [isOpen, setIsOpen] = useState(false);
    const [newBoardName, setNewBoardName] = useState('');
    const [selectedBoard, setSelectedBoard] = useState('');

    useEffect(() => {
        dispatch(fetchBoards());
    }, [dispatch]);

    const handleAddItemClick = () => {
        setIsOpen(true);
    };

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            const newBoard = await dispatch(createBoard({ name: newBoardName, userId })).unwrap();
            dispatch(addBoardItem({ boardId: newBoard._id, content: itemContent, itemType, userId }));
            setIsOpen(false);
        }
    };

    const handleSelectBoard = async (boardId) => {
        dispatch(addBoardItem({ boardId, content: itemContent, itemType, userId }));
        setIsOpen(false);
    };

    return (
        <div>
            <button className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded" onClick={handleAddItemClick}>
                <FontAwesomeIcon icon={faPaperclip} />
            </button>
            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
                    <div className="bg-body p-6 rounded shadow-lg">
                        <h2 className="text-xl mb-4">Select a Board</h2>
                        <div className="mb-4">
                            {boards.length === 0 && (
                                <p className="text-gray-600">No boards available. Create a new board.</p>
                            )}
                            {boards.map(board => (
                                <div key={board._id} className="mb-2">
                                    <button
                                        className="bg-body border border-primary hover:bg-gray-700 text-body hover:text-white px-4 py-2 rounded w-full text-left"
                                        onClick={() => handleSelectBoard(board._id)}
                                    >
                                        {board.name}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="mb-4">
                            <input
                                type="text"
                                placeholder="New Board Name"
                                value={newBoardName}
                                onChange={(e) => setNewBoardName(e.target.value)}
                                className="border text-black rounded px-4 py-2 w-full"
                            />
                        </div>
                        <div className="flex justify-end">
                            <button
                                className="bg-gray-500 text-white text-poetsen px-4 py-2 rounded mr-2"
                                onClick={() => setIsOpen(false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-primary text-poetsen text-body px-4 py-2 rounded"
                                onClick={handleCreateBoard}
                            >
                                Create and Add
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BoardSelectorButton;
