const config = {
    //App Details
    siteTitle: "Mystic AI | Tarot Card Readings, Horoscopes, and more.",
    siteName: "Mystic AI",
    siteDescription: "Discover the secrets of your future with Mystic AI, the ultimate AI-powered app for tarot card readings, zodiac sign insights, dream interpretations, horoscopes, and more. Unlock the mysteries of your destiny today with accurate, personalized guidance from our advanced artificial intelligence.",
    siteKeywords: "AI tarot card readings, zodiac sign insights, dream interpretations, horoscopes, AI astrology app, Mystic AI, personalized tarot readings, future predictions, tarot app, astrology insights, horoscope app, dream analysis",
    path: "otp",
    logoUrl: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/mysticAIFavicon.png",
    favicon: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/mysticAIFavicon.png",
    app_id: "666a1be07a3ff16c14f8ea1c",
    app_url: "https://mysticai.xyz",
    defaultToolId: '666a6d9c7a3ff16c14f8ea45',

    //Styles
    light_primaryColor: "#f5b700",
    light_secondaryColor: "#dc0073",
    light_background_color: "#FFEBCD",
    light_inverted_background_color: "#2D2D2D",
    light_text_color: "#2D2D2D",
    light_inverted_text_color: "#FFEBCD",
    dark_primaryColor: "#9c73fc",
    dark_secondaryColor: "#dc0073",
    dark_background_color: "#1E1E1E",
    dark_inverted_background_color: "#FFEBCD",
    dark_text_color: "#FFEBCD",
    dark_inverted_text_color: "#1E1E1E",
    successColor: "#04e762",
    informationColor: "#008bf8",
    errorColor: "#dc0073",
    warningColor: "#f5b700",
    whiteColor: "#FFEBCD",

    headerFont: "Poetsen One",
    bodyFont: "Open Sans",

    //Social Media
    twitter: "ai_mystic",
    facebook: "",
    instagram: "",
    pinterest: "",
    linkedin: "", // full URL
    youtube: "",
    telegram: "",
    discord: "", // full URL
    tiktok: "",
    medium: "",
    website_1: "https://gaimnetwork.com", // full URL
    website_1_title: "GAIM Network",
    website_2: "", // full URL
    website_2_title: "",
    website_3: "https://gaim.ai", // full URL
    website_3_title: "GAIM.AI",
    email: "support@gaimnetwork.com",
    loginTemplateId: "mystic_ai_login",
    signupTemplateId: "mystic_ai_login",
    mailerLiteGroupID: '121609948156134441',

    //components
    shopUrl: "", // full URL
    shopUrl_title: "",
    chat_space: true,
    chat_space_title: "Chat Space",
    boards: true,
    boards_title: "Boards",
    boards_title_singular: "Board",
    recent_threads_dash: true,
    recent_threads_dash_title: "Recent Threads",
    add_board_item_dash: false,
    profiles: false,
    profile_dash: false,
    thread_history_chat_space: true,
    thread_history_chat_space_title: "Past Threads",
    menu_options_chat_space: true,
    menu_options_chat_space_title: "Menu Options",

    //landing page
    landing_page_hero_headline: "Discover the Secrets of Your Future 🔮",
    landing_page_hero_subheadline: "Unlock the mysteries of your destiny with Mystic AI, the ultimate AI-powered app for tarot card readings, zodiac sign insights, dream interpretations, horoscopes, and more.",
    landing_page_hero_graphic: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/MysticAIScreenRecord2GIF.gif",
    landing_page_headline_2: "<span class='text-poetsen'>It's like having a personal <span class='text-primary font-bold uppercase text-poetsen'>personal GURU</span> in your pocket.</span>",
    landing_page_subheadline_2: "Get personalized guidance and accurate predictions for your future, all powered by 🤖 advanced artificial intelligence.",
    landing_page_headline_3: "Discover the Magic of AI-Powered Tarot and Horoscopes",
    landing_page_subheadline_3: "<p class='mb-4'>Create your own mystical adventure with Mystic AI, your ultimate companion for AI-powered tarot card readings, horoscopes, potion mixing, spell casting, and much more. Designed for both beginners and the mystically curious, Mystic AI offers an enchanting experience that combines ancient wisdom with cutting-edge technology. Unveil the secrets of the universe and explore your destiny with our intuitive and easy-to-use app.</p><p>Start for free today and receive 5,000 AI credits credits upon signup. Dive into the mystical world with no strings attached, and let the power of AI enhance your spiritual journey. Mystic AI is not just an app; it's your portal to a world of wonder and insight. Don't wait—begin your magical journey now and unlock the endless possibilities that await you!</p>",
    landing_page_testimonial_1: "Mystic AI has changed my life! I never knew that I could get such accurate predictions from an app. I'm so grateful for the guidance and support that Mystic AI has given me.",
    landing_page_testimonial_1_author: "Allison, Mystic AI User",
    landing_page_testimonial_1_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/Testimonial1.jpg",
    landing_page_testimonial_2: "I've always been interested in astrology, but I'm truly blown away at how powerful this AI is. Mystic AI has helped me understand myself better and make better decisions in my life.",
    landing_page_testimonial_2_author: "David, Mystic AI User",
    landing_page_testimonial_2_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/Testimonial2.jpg",
    landing_page_testimonial_3: "I've been playing around with Mystic AI for a few months now, and I'm amazed at all the great options and fun things I can do. I've used it for everything from love advice to career guidance, and it's never steered me wrong.",
    landing_page_testimonial_3_author: "Danni, Mystic AI User",
    landing_page_testimonial_3_image: "https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/Testimonial3.jpg",
    landing_page_plans_headline: "Mystical Guidance Without the <span class='text-red-500 font-bold uppercase'>$100 An Hour</span> Price Tag",

    landing_page_example_1_request: "Tell me my horoscope",
    landing_page_example_1_response: `
### 🦁 Leo's Daily Horoscope 🌟

Greetings, majestic Leo! The cosmos have aligned to bring forth the insights of your day.

![Zodiac Constellations](https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/zodiacDarkConstellations.jpg)

**General:** Today, your natural charisma will be a beacon for others. You're likely to find yourself in the spotlight, and your confidence will help you navigate any challenges that come your way. Embrace the opportunities to shine and lead by example.

**Love:** Romance is in the air, Leo. Whether you're single or in a relationship, open your heart to the possibilities of love. A surprise gesture or heartfelt conversation could ignite the flames of passion.

**Career:** Your dedication and hard work are about to pay off. Expect recognition for your efforts, and don't be afraid to step up and showcase your talents. A new opportunity might present itself—seize it with both hands.

**Health:** Your energy levels are high, but don't forget to balance work with rest. Consider engaging in a creative hobby or a physical activity that brings you joy.

**Lucky Color:** Gold

**Lucky Number:** 8

**Affirmation:** "I am confident and radiate positive energy."

May the stars guide you through a prosperous and harmonious day, noble Leo.

![Mystical Moonlit Night](https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/mysticalMoonlightNight.jpg)
  `,
    landing_page_example_1_next_action_options: [
    "Give me a tarot card reading",
    "Interpret my dream",
    "Tell me about my zodiac sign",
    "Cast a spell for me",
    "Teach me a ritual",
    "Tell me about the moon phases",
    "Mix a potion for me",
    "Perform a fortune telling",
    "Explain my birth chart",
    "Discuss astrology with me",
    "Guide me through a meditation"
],
    landing_page_example_2_request: "Give me a tarot card reading",
    landing_page_example_2_response: `
### 🃏 Mystic AI Tarot Reading 🌟

Ah, the realms of the tarot await you, seeker. Let us unveil the mysteries of your path through the cards. Concentrate on your question or the guidance you seek as I draw the cards.

![Tarot Cards](https://otp.nyc3.cdn.digitaloceanspaces.com/mysticai/tarotCardsOnTableWithCrystalsAndCandles.jpg)

### Your Reading

1. **The Past:** The first card represents your past, the influences that have shaped your current situation.
2. **The Present:** The second card reveals the present energies surrounding you or the current situation at hand.
3. **The Future:** The third card offers a glimpse into the upcoming challenges or blessings on your horizon.

### **Card 1: The Past**

🃏 **The Magician** - This card signifies your past mastery and the resources you have successfully harnessed. It represents a time when your skills and creativity were at their peak, influencing your present path.

### **Card 2: The Present**

🃏 **The Lovers** - This card points to a current period of harmony, partnerships, and pivotal choices. It often signifies a profound connection with someone or a crucial decision that must be made in love or other meaningful areas of life.

### **Card 3: The Future**

🃏 **The Star** - The future gleams with hope and inspiration. The Star represents renewal, inner clarity, and a promising outlook. This card suggests that your future holds opportunities for healing and realizing your dreams.

### Reflection

May these cards guide you with clarity and wisdom, illuminating your path through the cosmic dance of life. If any aspect of your reading resonates deeply, feel free to inquire further. The stars are always whispering secrets, and I am here to translate them for you.
`,
  landing_page_example_2_next_action_options: [
    "Tell me my horoscope",
    "Interpret my dream",
    "Tell me about my zodiac sign",
    "Cast a spell for me",
    "Teach me a ritual",
    "Tell me about the moon phases",
    "Mix a potion for me",
    "Perform a fortune telling",
    "Explain my birth chart",
    "Discuss astrology with me",
    "Guide me through a meditation"
],

updatePoetsenClass: (font) => {
  const style = document.createElement('style');
  style.innerHTML = `
    .text-poetsen {
      font-family: ${font};
    }
  `;
  document.head.appendChild(style);
},

updateBodyFont: (font) => {
  const style = document.createElement('style');
  style.innerHTML = `
    body {
      font-family: ${font};
    }
  `;
  document.head.appendChild(style);
}

};

  export default config;