import { Link } from "react-router-dom";
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";
import ThemeSwitcher from "../../../components/ThemeSwitcher";

const Layout = ({ Component }) => {
  return (
    <main className=" font-sans relative overflow-auto h-screen">
      <header className="h-16 lg:h-24 flex items-center z-50 w-full mt-0">
        <div className="container mx-auto px-6 flex items-center justify-between">
            <div className="uppercase text-3xl flex items-center h-24 mr-4 gap-5">
              <img
                className="h-10 lg:h-24 mx-auto rounded-full"
                src={config.logoUrl}
                alt={config.siteTitle}
              />
              <h1 className="text-poetsen text-lg lg:text-5xl text-primary">
                {config.siteName}
              </h1>
            </div>
          <div className="flex items-center">
            <nav className="font-sen justify-end flex flex-row gap-4">
              <ThemeSwitcher />
              <Link
                to={'/login'}
                className="uppercase py-2 px-4 border border-gray-900 text-poetsen bg-primary hover:bg-gray-700 text-white focus:ring-gray-900 focus:ring-offset-gray-900 w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
              >
                <FontAwesomeIcon icon={faRightToBracket} className="mr-2" />Login
              </Link>
            </nav>
          </div>
        </div>
      </header>
      <div className="flex relative z-20 items-center">
        <div className="container mx-auto px-4 flex flex-col justify-between items-center relative max-w-7xl">
          <Component />
        </div>
      </div>
    </main>
  );
};

export default Layout;
