// src/app/store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // This defaults to localStorage for web applications
import bannerReducer from '../features/games/bannersSlice';
import boardReducer from '../features/boards/boardSlice';
import boardItemReducer from '../features/boards/boardItemSlice';
import chatReducer from '../features/chat/chatSlice';
import currentProfileSlice from '../features/currentTool/currentProfileSlice';
import currentThemeReducer from '../features/currentTool/currentThemeSlice';
import currentToolReducer from '../features/currentTool/currentToolSlice';
import jsonModalReducer from '../features/modals/jsonModalSlice';
import menuReducer from '../features/mobile/menuSlice';
import merchItemsReducer from '../features/merch/merchSlice';
import modalComponentReducer from '../features/modals/modalcomponentSlice';
import modalIFrameReducer from '../features/modals/modaliframeSlice';
import modalNewsletterReducer from '../features/games/modalNewsletterSlice';
import modalPackagesReducer from '../features/packages/modalPackagesSlice';
import packageReducer from '../features/packages/packageSlice';
import packageItemReducer from '../features/packages/packageItemSlice';
import recordsReducer from '../features/tools/recordsSlice';
import selectedPackageReducer from '../features/packages/selectedPackageSlice';
import selectedProfileReducer from '../features/user/selectedProfileSlice';
import sessionReducer from '../features/session/sessionSlice';
import threadReducer from '../features/threads/threadSlice';
import threadHistoryReducer from '../features/threads/threadHistorySlice';
import toolReducer from '../features/tools/toolSlice';
import toolsModalReducer from '../features/tools/toolsModalSlice';
import uiReducer from '../features/ui/uiSlice';
import userReducer from '../features/user/userSlice';
import userPromptsReducer from '../features/userPrompts/userPromptsSlice';

// Persist configuration


const rootReducer = combineReducers({
  banners: bannerReducer,
  boards: boardReducer,
  boardItems: boardItemReducer,
  chat: chatReducer,
  currentProfile: currentProfileSlice,
  currentTheme: currentThemeReducer,
  currentTool: currentToolReducer,
  jsonModal: jsonModalReducer,
  menu: menuReducer,
  merchItems: merchItemsReducer,
  modalComponent: modalComponentReducer,
  modalIFrame: modalIFrameReducer,
  modalNewsletter: modalNewsletterReducer,
  modalPackages: modalPackagesReducer,
  packages: packageReducer,
  packageItems: packageItemReducer,
  records: recordsReducer,
  selectedPackage: selectedPackageReducer,
  selectedProfile: selectedProfileReducer,
  session: sessionReducer,
  thread: threadReducer,
  threadHistory: threadHistoryReducer,
  tools: toolReducer,
  toolsModal: toolsModalReducer,
  ui: uiReducer,
  user: userReducer,
  userPrompts: userPromptsReducer,
});

const persistConfig = {
  key: 'root',
  storage,
  // You can add whitelist or blacklist here to choose which reducers to persist
  blacklist: ['menu', 'toolsModal', 'ui', 'modalIFrame', 'modalPackages', 'thread'],
};

// Enhanced reducer with persistence capabilities
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store as usual, but replace the root reducer with the persistedReducer
export const store = configureStore({
  reducer: persistedReducer,
  // Add any middleware or enhancers here
});

// Create a persistor for your store
export const persistor = persistStore(store);

/*store.subscribe(() => {
  const state = store.getState();
  const servicesData = state.services.data;
  localStorage.setItem('servicesData', JSON.stringify(servicesData));
});*/