import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import config from '../config/config';

const SuggestedPrompts = ({setInputValue, handleSend, setShowMobileSuggestedPrompts = null}) => {
    const currentTool = useSelector(selectCurrentTool);

    const handleSubmit = (prompt) => {
      setInputValue(prompt);
      handleSend(prompt);
      if (setShowMobileSuggestedPrompts) {
        setShowMobileSuggestedPrompts(false);
      }
      
    };
  
    return (
      <div className="w-full">
        <div className="w-full text-poetsen flex flex-col gap-2 rounded shadow max-h-fit overflow-auto slim-scrollbar">
          <div className="flex items-center justify-center space-x-4 w-full">
            {config.menu_options_chat_space_title}
          </div>
            {currentTool?.suggested_prompts?.map((prompt, index) => (
              <div key={index} className="px-4 py-2 rounded-lg items-center cursor-pointer bg-primary hover:bg-gray-700 hover:text-gray-200 p-1 pl-2 border border-gray-700" onClick={() => handleSubmit(prompt)}>
                <FontAwesomeIcon icon={faWandMagicSparkles} className='mr-2' />{prompt}
              </div>
            ))}
          </div>
      </div>
    );
};

export default SuggestedPrompts;
