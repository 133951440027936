import HomepageLayout from "../layout/homepageLayout";
import Footer from "../components/Footer";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";
import PricingCardsMonthly from "../components/pricingCardsMonthly";
import FAQ from "../components/FAQ";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoltLightning, faHandHoldingDollar, faMedal, faRobot, faStar, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import config from "../../../config/config";
import { useSelector } from 'react-redux';
import { selectCurrentTool } from '../../../features/currentTool/currentToolSlice';
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const FeatureCard = ({ title, Icon, description }) => {
  return (
    <div class="flex items-start rounded-xl bg-primary p-6 shadow-lg border-2">
      <div class="flex h-12 w-12 items-center justify-center rounded-full border border-white text-secondary">
        <Icon size={20} />
      </div>
      <div class="mx-auto my-auto">
        <h2 class="font-semibold text-xl text-white text-poetsen">{title}</h2>
      </div>
    </div>
  );
};

const Home = () => {
  const currentTool = useSelector(selectCurrentTool);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <Card>
        <div className="max-w-full text-center">
          <div className="grid lg:grid-cols-2 gap-4">
            <div className="grid grid-cols-1 mb-4 md:my-auto order-1 lg:order-1">
              <div className="">
                <div className="md:text-5xl text-4xl font-bold mt-0 mx-auto max-w-6xl text-poetsen">
                  {config.landing_page_hero_headline}
                </div>
                <div className="text-xl bold mt-4 mx-auto max-w-4xl">
                  {config.landing_page_hero_subheadline}
                </div>
                <div className="mx-auto my-10 h-16 text-center ">
                  <button
                    onClick={() => navigate("/login")}
                    className="text-poetsen mt-2 py-2 md:px-6 px-10 border-2 border-primary hover:bg-gray-700 bg-primary focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-xl sm:text-3xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                  >
                    👉 Get Started For Free
                  </button>
                  <div className="mt-4 italic text-gray-600">
                    <FontAwesomeIcon icon={faMedal} className="mr-2 text-yellow-600" />7-day money back guarantee.
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className={`order-2 lg:order-2`}>
                <img src={config.landing_page_hero_graphic} alt={`Get started with ${config.siteTitle}`} className="h-100 max-h-100 md:max-w-sm border border-2 border-gray-400 shadow-xl rounded-xl mx-auto p-1 " style={{pointerEvents: "none"}} />
              </div>
          </div>
          <div className="max-w-screen-xl mx-auto mt-20 lg:mt-32 mb-24 lg:mb-10">
            <h2 className="text-poetsen text-4xl font-bold tracking-wide flex justify-center mb-3">
              <p dangerouslySetInnerHTML={{ __html: config.landing_page_headline_2 }}></p>
            </h2>
            <div className="text-xl">
              {config.landing_page_subheadline_2}
            </div>
          </div>
          <div className="grid lg:grid-cols-2 gap-4 ">
            <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
              <div class="lg:ml-4 h-full flex flex-col justify-between">
                <div className="text-center text-sm italic h-8">Example Interaction</div>
                <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                  {" "}
                  <span class="bg-primary text-white mr-2 px-4 py-2 rounded text-poetsen">
                    <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />{config.landing_page_example_1_request}
                  </span>
                </h2>

                <ReactMarkdown
                    className="markdown-content text-left"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {config.landing_page_example_1_response}
                  </ReactMarkdown>
                  <div className="flex flex-wrap justify-center gap-2">
                    {config.landing_page_example_1_next_action_options?.map((option, index) => (
                      <div key={index} className="flex items-center">
                        <span class="bg-primary text-white mr-2 px-4 py-2 rounded text-poetsen">
                          <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />{option}
                        </span>
                      </div>
                    ))}
                  </div>
              </div>
            </div>
            <div class="flex items-start rounded-xl p-4 shadow-lg border-2 border-primary">
              <div class="lg:ml-4 h-full flex flex-col justify-between">
                <div className="text-center text-sm italic h-8">Example Interaction</div>
                <h2 class="font-semibold lg:text-2xl lg:ml-3 text-center">
                  {" "}
                  <span class="bg-primary text-white mr-2 px-4 py-2 rounded text-poetsen">
                    <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />{config.landing_page_example_2_request}
                  </span>
                </h2>

                <ReactMarkdown
                    className="markdown-content text-left"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {config.landing_page_example_2_response}
                  </ReactMarkdown>
                  <div className="flex flex-wrap justify-center gap-2">
                    {config.landing_page_example_2_next_action_options?.map((option, index) => (
                      <div key={index} className="flex items-center">
                        <span class="bg-primary text-white mr-2 px-4 py-2 rounded text-poetsen">
                          <FontAwesomeIcon icon={faWandMagicSparkles} className="mr-2" />{option}
                        </span>
                      </div>
                    ))}
                  </div>
              </div>
            </div>
          </div>
          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>
          <div class="flex flex-col my-20 ">
            <h2 class="mb-4 text-4xl font-bold text-center text-poetsen">
              {" "}
              <FontAwesomeIcon icon={faWandMagicSparkles} className="text-secondary mr-3" />{config.siteName} Features
            </h2>

            <div class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-3 ">
              {currentTool?.suggested_prompts?.map((prompt, index) => (
                <FeatureCard
                  key={index}
                  title={prompt}
                  Icon={()=> <FontAwesomeIcon icon={faWandMagicSparkles} />}
                  //description={prompt.description}
                />
              ))}
            </div>
          </div>
          
          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>

          <div className="my-20">
            <div className="text-poetsen text-center text-4xl font-bold flex flex-col lg:flex-row justify-center items-center">
              Don't just take it from us...
              <div><FontAwesomeIcon icon={faStar} className="text-yellow-400 ml-2" /><FontAwesomeIcon icon={faStar} className="text-yellow-400" /><FontAwesomeIcon icon={faStar} className="text-yellow-400" /><FontAwesomeIcon icon={faStar} className="text-yellow-400" /><FontAwesomeIcon icon={faStar} className="text-yellow-400" /></div>
            </div>

            <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto  border border-2 border-primary rounded-xl shadow-md py-2 px-4">
              <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="">
                  <span className="text-primary text-5xl text-poetsen">"</span>{config.landing_page_testimonial_1}
                  <p className="text-primary text-5xl text-poetsen text-right">"</p>
                  <img className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary" src={config.landing_page_testimonial_1_image} />
                  <p className="mt-4 font-semibold">{config.landing_page_testimonial_1_author}</p>
                  <img className="mb-4 mt-4 w-28 mx-auto" src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png" />
                </div>
              </div>
              <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="">
                  <span className="text-primary text-5xl text-poetsen">"</span>{config.landing_page_testimonial_2}
                  <p className="text-primary text-5xl text-poetsen text-right">"</p>
                  <img className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary" src={config.landing_page_testimonial_2_image} />
                  <p className="mt-4 font-semibold">{config.landing_page_testimonial_2_author}</p>
                  <img className="mb-4 mt-4 w-28 mx-auto" src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png" />
                </div>
              </div>
              <div className=" max-w-2xl px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
                <div className="">
                  <span className="text-primary text-5xl text-poetsen">"</span>{config.landing_page_testimonial_3}
                  <p className="text-primary text-5xl text-poetsen text-right">"</p>
                  <img className="mb-4 mt-4 w-28 mx-auto rounded-full border-4 border-primary" src={config.landing_page_testimonial_3_image} />
                  <p className="mt-4 font-semibold">{config.landing_page_testimonial_3_author}</p>
                  <img className="mb-4 mt-4 w-28 mx-auto" src="https://otp.nyc3.cdn.digitaloceanspaces.com/5-star.png" />
                </div>
              </div>
            </div>
          </div>

          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>
          <div className="border-2 border-primary shadow-xl rounded-xl p-10 max-w-8xl mx-auto bg-gray-800 text-white mt-10 flex flex-col lg:flex-row lg:justify-center">
            <div className="hidden lg:flex justify-center items-center px-10">
              <img className="w-48 mx-auto rounded-full border-2" src={config.favicon} alt={config.siteName} />
            </div>
            <div className="text-center">
              <FontAwesomeIcon icon={faWandMagicSparkles} className="bg-secondary text-5xl mx-auto mb-4 border-2 border-primary p-3 py-3.5 rounded-full" />
              <h2 className="text-3xl font-bold tracking-wide text-poetsen flex justify-center mb-4">
                {config.landing_page_headline_3}
              </h2>
              <div className="flex flex-row gap-4 justify-center items-center">
                <FontAwesomeIcon icon={faBoltLightning} className="text-secondary text-xl mb-4 border-2 border-primary p-4 py-3.5 rounded-full" />
                <FontAwesomeIcon icon={faWandMagicSparkles} className="text-secondary text-xl mb-4 border-2 border-primary p-3 py-3.5 rounded-full" />
                <FontAwesomeIcon icon={faRobot} className="text-secondary text-xl mb-4 border-2 border-primary p-2.5 py-3.5 rounded-full" />
              </div>
              <p className="max-w-3xl mx-auto text-lg">
                <p dangerouslySetInnerHTML={{ __html: config.landing_page_subheadline_3 }}></p>
              </p>
              <button
                onClick={() => navigate("/login")}
                className="mt-6 py-2 md:px-6 px-2  border-gray-200 hover:bg-gray-700 hover:text-white bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
              >
                👉 Start For Free
              </button>
            </div>
          </div>
          <div class="mb-10 flex justify-center items-center  ">
            <div class="mt-10">
              <div className="text-poetsen text-center text-4xl font-bold mt-10 flex flex-col lg:flex-row gap-2 lg:gap-0">
                <p dangerouslySetInnerHTML={{ __html: config.landing_page_plans_headline }}></p><div><FontAwesomeIcon icon={faHandHoldingDollar} className="text-red-500 lg:ml-2" /></div>
              </div>
              <PricingCardsMonthly
                freeLink=""
                starterLink=""
                standardLink=""
                businessLink=""
                showFree={false}
              />
            </div>
          </div>
          <hr className="w-48 h-1 mx-auto bg-primary border-0 rounded my-20"></hr>
          <FAQ />

          <div className="p-8 mt-6 rounded-xl shadow-xl bg-primary text-white">
            <div className="mb-6 font-bold text-2xl">
              Get instant access <FontAwesomeIcon icon={faBoltLightning} className="mx-2 text-yellow-400" /> and start using {config.siteName} today.
            </div>
            <button
              onClick={() => navigate("/login")}
              className="mt-6 py-2 md:px-6 px-2  border-gray-200 hover:bg-gray-700 hover:text-white bg-white focus:ring-white-500 focus:ring-offset-white-200 text-primary transition ease-in duration-200 text-center text-2xl font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              👉 Start For Free
            </button>

            <div className="mt-4 italic text-gray-200">
              <FontAwesomeIcon icon={faMedal} className="mr-2 text-yellow-600" />7-day money back guarantee
            </div>
          </div>
          <Footer />
        </div>
      </Card>
    </div>
  );
};

const Homepage = () => {
  return <HomepageLayout Component={Home} />;
};

export default Homepage;
