// src/components/AddBoardItemForm.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBoardItem, fetchBoardItems } from '../features/boards/boardItemSlice';
import { selectUser } from '../features/user/userSlice';
import { addToast } from '../features/ui/uiSlice';
import config from '../config/config';

const AddBoardItemForm = ({ boardId, onFormToggle }) => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [content, setContent] = useState('');
    const [itemType, setItemType] = useState('text'); // default to text

    const handleAddItem = async () => {
        if (content.trim()) {
            try {
                await dispatch(addBoardItem({ boardId, content, itemType, userId: user._id })).unwrap();
                dispatch(addToast(`Item Added to ${config.boards_title_singular}!`, 'success'));
                setContent(''); // clear the input
                setItemType('text'); // reset to default item type
                onFormToggle(false); // hide the form after adding the item
                dispatch(fetchBoardItems(boardId)); // refresh the board items
            } catch (error) {
                console.error('Failed to add item:', error);
                dispatch(addToast('Failed to add item!', 'error'));
            }
        }
    };

    return (
        <div className="mb-4">
            <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Paste text, image URL, or prompt here..."
                className="border rounded px-4 py-2 w-full mb-2 text-black"
            />
            <select
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
                className="border rounded px-4 py-2 mb-2 w-full text-black"
            >
                <option value="text">Text</option>
                <option value="image">Image</option>
                <option value="prompt">Prompt</option>
            </select>
            <button
                onClick={handleAddItem}
                className="bg-body px-4 py-2 rounded w-full text-primary hover:bg-gray-700 hover:text-white"
            >
                Add Item
            </button>
        </div>
    );
};

export default AddBoardItemForm;
