import React from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({ url, width }) => {
  const handleVideoError = (e) => {
    console.error('Error loading video:', e);
    alert('Error loading video. Please try again later.');
  };

  const handleVideoLoaded = () => {
    console.log('Video loaded successfully');
  };

  return (
    <div style={{ width: width, margin: '0 auto' }}>
      <video
        width="100%"
        autoPlay
        loop
        muted
        onError={handleVideoError}
        onLoadedData={handleVideoLoaded}
        className='rounded-lg border-4 border-primary border-double'
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default VideoPlayer;
