import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RequireAuth from './components/RequireAuth';
import ScrollToTop from './components/ScrollToTop';
import Layout from './components/layout';
import Board from './components/Board';
import BoardItem from './components/BoardItem';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Home from './pages/Home';
import HomePage from './pages/Homepage';
import Login from './pages/Login';
import LoggingIn from './pages/LoggingIn';
import PackagePage from './pages/PackagePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import Settings from './pages/Settings';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import Workspace from './pages/Workspace';
import { CheckoutForm } from './components/StripeCheckout';
import { Return } from './components/StripeCheckout';

const AppRouter = () => (
  <Router>
    <ScrollToTop />
    <Routes>
      {/* Routes without Layout */}
      <Route path="/login" element={<Login />} />
      <Route path="/loggingin" element={<LoggingIn />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />

      {/* Routes with Layout */}
      <Route path="/" element={<RequireAuth fallback={<HomePage />}><Layout><Home /></Layout></RequireAuth>} />
      <Route path="/boards" element={<RequireAuth><Layout><Board /></Layout></RequireAuth>} />
      <Route path="/boards/:id" element={<RequireAuth><Layout><BoardItem /></Layout></RequireAuth>} />
      <Route path="/dashboard" element={<RequireAuth><Layout><Dashboard /></Layout></RequireAuth>} />
      <Route path="/packages" element={<RequireAuth><Layout><PackagePage /></Layout></RequireAuth>} />
      <Route path="/profile" element={<RequireAuth><Layout><Profile /></Layout></RequireAuth>} />
      <Route path="/settings" element={<RequireAuth><Layout><Settings /></Layout></RequireAuth>} />
      <Route path="/workspace" element={<RequireAuth><Layout><Workspace /></Layout></RequireAuth>} />
      <Route path="/checkout" element={<RequireAuth><Layout><CheckoutForm /></Layout></RequireAuth>} />
      <Route path="/return" element={<RequireAuth><Layout><Return /></Layout></RequireAuth>} />
    </Routes>
  </Router>
);

export default AppRouter;
