import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectSession, deleteSession, setSession } from '../features/session/sessionSlice';
import { authUser } from '../api/userFunctions';
import { setUser, selectUser } from '../features/user/userSlice';
import Cookies from 'js-cookie';

const RequireAuth = ({ children, fallback }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const session = useSelector(selectSession);
    const user = useSelector(selectUser);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyAuth = async () => {
            if (!session || !user) {
                setIsAuthenticated(false);
            } else {
                try {
                    const user = await authUser(session.stytch_session_token);
                    if (user.success) {
                        setIsAuthenticated(true);
                        dispatch(setUser(user.data));
                        dispatch(setSession({
                            stytch_session_token: session.stytch_session_token,
                        }));
                    } else {
                        /*Cookies.remove('stytch_session');
                        Cookies.remove('stytch_session_jwt');
                        dispatch(setUser(null));
                        dispatch(deleteSession());*/
                        setIsAuthenticated(false);
                    }
                } catch (error) {
                    /*Cookies.remove('stytch_session');
                    Cookies.remove('stytch_session_jwt');
                    dispatch(setUser(null));
                    dispatch(deleteSession());*/
                    setIsAuthenticated(false);
                }
            }
            setIsLoading(false);
        };

        verifyAuth();
    }, [navigate]);

    useEffect(() => {
        if (!isLoading && !isAuthenticated && !fallback) {
            navigate('/login');
        }
    }, [isLoading, isAuthenticated, fallback, navigate]);

    if (isLoading) return <div>Loading...</div>;
    return isAuthenticated ? children : fallback || null;
};

export default RequireAuth;
