import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { selectSession } from '../features/session/sessionSlice';
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from '../features/threads/threadSlice';
import { selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { generateChat, postMessage } from '../api/aiFunctions';
import { selectCurrentPrompt, setResponse, setLoading, setError, setCurrentPrompt } from '../features/chat/chatSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowRotateRight, faCopy, faSave, faArrowRight, faChevronDown, faWandMagic, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import socket from '../utils/socket';
import { copyToClipboard } from '../utils/commons';
import { addToast } from '../features/ui/uiSlice';
import SavePrompt from './SavePrompt';
import ModalPromptSave from './ModalPromptSave';
import mermaid from "mermaid";
import markdownit from 'markdown-it'
import hljs from "highlight.js";
import markdownItMermaid from "markdown-it-mermaid";
import taskLists from "markdown-it-task-lists";
import mdVideos from "markdown-it-video";
import miliMarkdown from "markdown-it-linkify-images";
import { full as emoji } from 'markdown-it-emoji'

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const MessagesArea = styled.div`
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
`;

const Message = styled.div`
  background-color: ${props => props.isUser ? '#99abd6' : '#fff'};
  padding: 8px 10px;
  margin: 5px;
  border-radius: 8px;
  text-align: ${props => props.isUser ? 'right' : 'left'};
  color: #121212;
  width: ${props => props.messageCount > 1 ? '49%' : '100%'};
`;

const InputArea = styled.div`
  display: flex;
  padding: 10px;
  background-color: #eee;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  color: #121212;
`;

const SendButton = styled.button`
  padding: 10px 15px;
  background-color: #99abd6;
  color: #121212;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const Chatbot = () => {
    const user = useSelector(selectUser);
    const session = useSelector(selectSession);
    const currentTool = useSelector(selectCurrentTool);
    const currentPrompt = useSelector((state) => state.chat.currentPrompt);
    //const servicesData = useSelector((state) => state.services.data);
    const dispatch = useDispatch();
    const currentThreadId = useSelector(selectCurrentThreadId);
    const [messages, setMessages] = useState([]);
    const [messagesToSubmit, setMessagesToSubmit] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [submittedMessages, setSubmittedMessages] = useState([]);
    const [awaitingResponse, setAwaitingResponse] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [result, setResult] = useState('');
    const [result2, setResult2] = useState('');
    const messagesEndRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownLabel, setDropdownLabel] = useState('Make it...');
    const navigate = useNavigate();
    const location = useLocation();
    

    const md = markdownit({
      html: true,
      linkify: true,
      typographer: true,
      breaks: true,
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return '<pre><code class="hljs">' +
                   hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
                   '</code></pre>';
          } catch (__) {}
        }
    
        return '<pre><code class="hljs">' + md.utils.escapeHtml(str) + '</code></pre>';
      }
    }).use(markdownItMermaid).use(taskLists, { enabled: true }).use(mdVideos, {
      youtube: { width: 640, height: 390 },
      vimeo: { width: 500, height: 281 },
      vine: { width: 600, height: 600, embed: 'simple' },
      prezi: { width: 550, height: 400 }}
    ).use(emoji);

    function MarkdownComponent({ markdownText }) {
      const renderedMarkdown = md.render(markdownText);
    
      return (
        <div dangerouslySetInnerHTML={{ __html: renderedMarkdown }} />
      );
    }
    

    const handleCopy = async (text) => {
      const isCopied = await copyToClipboard(text);
      if (isCopied) {
          dispatch(addToast('Copied to clipboard!', 'success'));
      } else {
          dispatch(addToast('Failed to copy!', 'error'));
      }
    }

  const handleSend = async (text = inputValue) => {
    if (currentTool.type === 'assistant' && text.trim() !== '') {
      setMessages(messages => [...messages, { id: messages.length + 1, text, isUser: true }]);
      setResult(`Generating response...`);
      const postAssistant = await postMessage(user.dynamic_id, text, currentThreadId, currentTool.oai_assistantId, session.stytch_session_token);
      setResult('');
      console.log('postAssistant:', postAssistant);
      if (postAssistant) {
        setMessages(messages => [
            ...messages,
            {
                id: messages.length + 1,
                text: postAssistant.data,
                isUser: false,
                service: 'openai',
                messageCount: 1,
            }
        ]);
        dispatch(setThreadId(postAssistant.threadId));
        setInputValue('');
        setDropdownLabel('Make it...'); 
        //setAwaitingResponse(true);
      }
    } else {
      if (text.trim() !== '') {
        const newMessage = { role: 'user', content: text };
        setMessages(messages => [...messages, { id: messages.length + 1, text, isUser: true }]);
        setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
        setInputValue('');
        setAwaitingResponse(true);
      }
    }
  };

  const handleRegenerate = (messageId, isUser) => {
    if (isUser) {
      // If the icon clicked is from a user's message, simply resend that message
      const messageToRegenerate = messages.find(message => message.id === messageId);
      handleSend(messageToRegenerate.text);
    } else {
      // If the icon clicked is from an assistant's response, find the preceding user's message to regenerate
      for (let i = messageId - 1; i >= 0; i--) {
        const previousMessage = messages[i];
        if (previousMessage.isUser) {
          handleSend(previousMessage.text);
          break;
        }
      }
    }
  };

  useEffect(() => {
    const checkScroll = () => {
      if (messagesEndRef.current) { // Check if messagesEndRef.current is not null
        const isAtBottom = messagesEndRef.current.scrollHeight - messagesEndRef.current.scrollTop === messagesEndRef.current.clientHeight;
        setShowScrollButton(!isAtBottom);
      }
    };
  
    // Only add event listener if messagesEndRef.current is not null
    if (messagesEndRef.current) {
      messagesEndRef.current.addEventListener('scroll', checkScroll);
    }
  
    return () => {
      // Use if-check to ensure cleanup doesn't cause errors
      if (messagesEndRef.current) {
        messagesEndRef.current.removeEventListener('scroll', checkScroll);
      }
    };
  }, [messagesEndRef.current]); 
  
  const scrollToBottom = () => {
    messagesEndRef.current.scrollTo({
      top: messagesEndRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (currentPrompt) {
      const fetchChatResponse = async () => {
        const newMessage = { role: 'user', content: currentPrompt };
        setMessages(messages => [...messages, { id: messages.length + 1, text: currentPrompt, isUser: true }]);
        setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, newMessage]);
        //setInputValue('');
        dispatch(setCurrentPrompt(null));
        setAwaitingResponse(true);
        if (location.pathname !== '/workspace') {
          navigate('/workspace');
        }
      };

      fetchChatResponse();
    }
  }, [currentPrompt, dispatch]);

  const handleNewChat = () => {
    setMessages([]);
    setMessagesToSubmit([]);
    setSubmittedMessages([]);
    setAwaitingResponse(false);
    setResult('');
    setResult2('');
    dispatch(setThreadId(null));
  };

  useEffect(() => {
    console.log('Current Thread ID:', currentThreadId)
    if (!currentThreadId) {
      handleNewChat();
    }
  }, [currentThreadId]);

  /*useEffect(() => {
    let accumulatingMessageContent = '';
    let accumulatingMessageId = messages.length + 1; // Initialize with the next message ID
  
    const messageListener = (message) => {
      if (user && user._id && user.dynamic_id && message.userId && message.userId === user._id) {
        if (message.chunkContent) {
          accumulatingMessageContent += message.chunkContent;
          setResult((prevResult) => prevResult + message.chunkContent);
          if (accumulatingMessageContent.length === message.chunkContent.length) {
            // 
          } else {
            // Update the accumulating message with new content
            setMessages(messages => messages.map(message => 
              message.id === accumulatingMessageId ? { ...message, text: accumulatingMessageContent } : message
            ));
          }
        }
      }
    };
  
    socket.on("chatStream", messageListener);
  
    return () => {
      socket.off("chatStream", messageListener);
    };
  }, [socket, messages.length, user]);*/

  useEffect(() => {
    // Initialize separate accumulators for each service
    const accumulators = {
      openai: {
        content: '',
        messageId: messages.length + 1, // Assuming separate message IDs for each service
      },
      perplexity: {
        content: '',
        messageId: messages.length + 2, // Adjust as needed
      }
    };
  
    const messageListener = (message) => {
      if (user && user._id && user.dynamic_id && message.userId && message.userId === user._id) {
        // Check the serviceName in the incoming message
        const serviceName = message.serviceName;
        const accumulator = accumulators[serviceName];
  
        if (message.chunkContent) {
          accumulator.content += message.chunkContent;
          console.log('Chunk Content:', message.chunkContent);
          // Use setResult or setResult2 based on the serviceName
          if (serviceName === 'openai') {
            setResult(prevResult => prevResult + message.chunkContent);
          } else if (serviceName === 'perplexity') {
            setResult2(prevResult => prevResult + message.chunkContent);
            console.log('Perplexity:', message.chunkContent);
          }
  
          // If message indicates completion, add the accumulated message to setMessages
          if (message === '--complete--') {
            setMessages(messages => [
              ...messages,
              { id: accumulator.messageId, text: accumulator.content, isUser: false, service: serviceName }
            ]);
            accumulator.content = ''; // Reset accumulator content after adding to messages
          } else {
            // Optionally handle partial updates or just accumulate until completion
          }
        }
      }
    };
  
    socket.on("chatStream", messageListener);
  
    return () => {
      socket.off("chatStream", messageListener);
    };
  }, [socket, messages.length, user]); // Ensure useEffect dependencies are correctly set
  

  /*useEffect(() => {  
    if (messagesToSubmit.length > submittedMessages.length && awaitingResponse) {
      if (servicesData.openai && servicesData.perplexity) {
        setResult2(`Generating response from Perplexity AI...`);
      }
      const callGenerateChat = async () => {
        try {
          
            const lastNewMessage = messagesToSubmit[messagesToSubmit.length - 1];
          const chatResponse = await generateChat(
            user.dynamic_id,
            messagesToSubmit,
            lastNewMessage.content,
            'Best',
            currentTool._id,
            servicesData,
            session.stytch_session_token,
          );
          console.log('Chat Response:', chatResponse);

          if (chatResponse.success && Array.isArray(chatResponse.data)) {
            const messageCount = chatResponse.data.length;
        
            // If there are exactly two responses, combine them into one message object
            if (messageCount === 2) {
                // Create a single message with text from both responses
                const combinedMessage = {
                    id: messages.length + 1,
                    text: chatResponse.data[0].fullContent, // Content from the first response
                    text2: chatResponse.data[1].fullContent, // Content from the second response
                    isUser: false,
                    service: chatResponse.data[0].serviceName, // Service name from the first response
                    service2: chatResponse.data[1].serviceName, // Service name from the second response
                    messageCount: messageCount,
                };
                // Add the combined message to the messages array
                setMessages(messages => [...messages, combinedMessage]);
                // Optionally, add to messagesToSubmit if needed
                setMessagesToSubmit(messagesToSubmit => [
                    ...messagesToSubmit, 
                    { role: 'assistant', content: combinedMessage.text },
                    { role: 'assistant', content: combinedMessage.text2 }
                ]);
            } else {
                // For other cases (1 or more than 2 responses), add each as its own message
                chatResponse.data.forEach((responseItem, index) => {
                    setMessages(messages => [
                        ...messages,
                        {
                            id: messages.length + 1,
                            text: responseItem.fullContent,
                            isUser: false,
                            service: responseItem.serviceName,
                            messageCount: messageCount,
                            // If it's the second message, assign it to text2 and service2 instead
                            ...(index === 1 && { text2: responseItem.fullContent, service2: responseItem.serviceName }),
                        }
                    ]);
                    setMessagesToSubmit(messagesToSubmit => [
                        ...messagesToSubmit, 
                        { role: 'assistant', content: responseItem.fullContent }
                    ]);
                });
            }
        } else {
            // Handle cases where the chatResponse is not successful or doesn't contain the expected data
            console.log('No images received or an error occurred');
        }
        

          //setMessages(messages => [...messages, { id: messages.length + 1, text: chatResponse.data, isUser: false }]);
          //setMessagesToSubmit(messagesToSubmit => [...messagesToSubmit, { role: 'assistant', content: chatResponse.data }]);
          setResult('');
          setResult2('');
            setSubmittedMessages(submittedMessages => [...submittedMessages, lastNewMessage]);
            setAwaitingResponse(false);
        } catch (error) {
          console.error('Failed to generate chat:', error);
          setAwaitingResponse(false);
        }
      };
  
      callGenerateChat();
    }
  }, [messagesToSubmit, currentTool, session, user]);*/
  
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  const dropdownOptions = ["a checklist", "shorter", "longer", "more difficult", "less difficult"];

  const handleDropdownSelect = (option) => {
    setIsDropdownOpen(false); // Close dropdown
    setDropdownLabel(option); // Update button label
    handleSend(`Regenerate your previous message to be ${option}.`);
    console.log(`API call for option: ${option}`);
  };
  
  const servicesMap = [
    { id: 'perplexity', label: 'Perplexity AI', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/perplexityLogo.jpg' },
    { id: 'openai', label: 'Open AI (DALLE)', image_url: 'https://byok.nyc3.digitaloceanspaces.com/images/openAILogo.jpg' }
  ];

  return (
    <ChatContainer>
      <MessagesArea ref={messagesEndRef}>
      {messages.map((message, index) => (
        <div 
        key={message.id} 
        className={`message ${message.isUser ? 'user-message' : 'assistant-message'}`}
        style={{
          display: 'flex',
          flexDirection: message.messageCount === 2 ? 'row' : 'column',
          justifyContent: message.messageCount === 2 ? 'space-between' : 'flex-start',
          width: '100%',
        }}
      >
        <Message key={message.id} isUser={message.isUser} messageCount={message.messageCount}>
          {!message.isUser && currentTool?.image_data?.example_url && (
            <div className='flex flex-row items-center'>
              <img 
                src={currentTool.image_data.example_url} 
                alt={currentTool.name} // Provide an appropriate alt attribute
                style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
              />
              <FontAwesomeIcon icon={faArrowRight} className="mr-2 ml-2 mb-2" />
              {message.service && (
                <img 
                  src={servicesMap.find(service => service.id === message.service).image_url} 
                  alt={currentTool.name} // Provide an appropriate alt attribute
                  style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                />
              )}
              
            </div>
          )}
          {/*<ReactMarkdown>{message.text}</ReactMarkdown>
          <ReactMarkdown children={message.text} remarkPlugins={[remarkGfm]} />*/}
          <div className='markdown-content'>
            {MarkdownComponent({ markdownText: message.text })}
          </div>
          
          
          {!message.isUser ? (
            <div className='flex justify-between items-center border-t mt-5 pt-2'>
              <div>
                <FontAwesomeIcon icon={faArrowRotateRight} onClick={() => handleRegenerate(message.id, message.isUser)} className='ml-2 cursor-pointer hover:text-gray-600' />
                {/*<FontAwesomeIcon icon={faSave} onClick={() => handleCopy(message.text)} className='ml-2 cursor-pointer hover:text-gray-600' />*/}
                <FontAwesomeIcon icon={faCopy} onClick={() => handleCopy(message.text)} className='ml-2 cursor-pointer hover:text-gray-600' />
              </div>
              {index === messages.length - 1 && (<>
                <div className='relative w-1/5 mr-5 text-center'>
                  
                  <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className='flex justify-center w-full items-center cursor-pointer bg-primary text-inverted p-1 pl-3 pr-3 rounded border mr-5'>
                  <FontAwesomeIcon icon={faWandMagicSparkles} className='mr-2' />{dropdownLabel} <FontAwesomeIcon icon={faChevronDown} className='ml-2' />
                  </button>
                  {isDropdownOpen && (
                    <div className='absolute right-0 bottom-full bg-primary shadow-md mb-2 rounded w-full text-center'>
                      {dropdownOptions.map((option, index) => (
                        <div key={index} className='cursor-pointer p-1 pl-3 pr-3 text-inverted hover:text-black hover:bg-gray-300' onClick={() => handleDropdownSelect(option)}>
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>)}
              
            </div>
          ) : (
            <div className='border-t border-gray-500 mt-2 pt-2' style={{marginLeft: '80%'}}>
              <FontAwesomeIcon icon={faArrowRotateRight} onClick={() => handleRegenerate(message.id, message.isUser)} className='ml-2 cursor-pointer hover:text-gray-600' />
              <FontAwesomeIcon icon={faSave} onClick={() => setIsModalOpen(true)} className='ml-2 cursor-pointer hover:text-gray-600' />
              <ModalPromptSave isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <SavePrompt prompt={message.text} onClose={() => setIsModalOpen(false)} />
              </ModalPromptSave>
              <FontAwesomeIcon icon={faCopy} onClick={() => handleCopy(message.text)} className='ml-2 cursor-pointer hover:text-gray-600' />
            </div>
          )
        }
        </Message>
        {message.messageCount === 2 && (
          <Message key={message.id} isUser={message.isUser} messageCount={message.messageCount}>
          {!message.isUser && currentTool?.image_data?.example_url && (
            <div className='flex flex-row items-center'>
              <img 
                src={currentTool.image_data.example_url} 
                alt={currentTool.name} // Provide an appropriate alt attribute
                style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
              />
              <FontAwesomeIcon icon={faArrowRight} className="mr-2 ml-2 mb-2" />
              {message.service && (
                <img 
                  src={servicesMap.find(service => service.id === message.service2).image_url} 
                  alt={currentTool.name} // Provide an appropriate alt attribute
                  style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                />
              )}
              
            </div>
          )}
          <div className='markdown-content'>
            {MarkdownComponent({ markdownText: message.text2 })}
          </div>
          
          {!message.isUser ? (
            <div className='border-t mt-5 text-left pt-2' style={{marginRight: '80%'}}>
              <FontAwesomeIcon icon={faArrowRotateRight} onClick={() => handleRegenerate(message.id, message.isUser)} className='ml-2 cursor-pointer hover:text-gray-600' />
              {/*<FontAwesomeIcon icon={faSave} onClick={() => handleCopy(message.text)} className='ml-2 cursor-pointer hover:text-gray-600' />*/}
              <FontAwesomeIcon icon={faCopy} onClick={() => handleCopy(message.text2)} className='ml-2 cursor-pointer hover:text-gray-600' />
            </div>
          ) : (
            <div className='border-t border-gray-500 mt-2 pt-2' style={{marginLeft: '80%'}}>
              <FontAwesomeIcon icon={faArrowRotateRight} onClick={() => handleRegenerate(message.id, message.isUser)} className='ml-2 cursor-pointer hover:text-gray-600' />
              <FontAwesomeIcon icon={faSave} onClick={() => setIsModalOpen(true)} className='ml-2 cursor-pointer hover:text-gray-600' />
              <ModalPromptSave isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <SavePrompt prompt={message.text2} onClose={() => setIsModalOpen(false)} type={'text'} />
              </ModalPromptSave>
              <FontAwesomeIcon icon={faCopy} onClick={() => handleCopy(message.text2)} className='ml-2 cursor-pointer hover:text-gray-600' />
            </div>
          )
        }
        </Message>
        )}
      </div>
      ))}
      { // Check if either result or result2 has content
        (result && result !== '') || (result2 && result2 !== '') ? (
          <div style={{
            display: 'flex',
            flexDirection: 'row', // Align items in a row
            justifyContent: 'space-around', // Space out items
            width: '100%' // Container takes full width
          }}>
            {result && result !== '' && (
              <div style={{flex: result2 ? 1 : 1, padding: '0 10px'}}> {/* Adjust style based on presence of result2 */}
                <Message isUser={false}>
                <div className='flex flex-row items-center'>
                  {currentTool?.image_data?.example_url && (
                    <img 
                      src={currentTool.image_data.example_url} 
                      alt={`${currentTool.name} example`} // Provide an appropriate alt attribute
                      style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                    />
                  )}
                  <FontAwesomeIcon icon={faArrowRight} className="mr-2 ml-2 mb-2" />
                  <img 
                    src={servicesMap.find(service => service.id === 'openai').image_url} 
                    alt={currentTool.name} // Provide an appropriate alt attribute
                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                    className='rotate-infinite'
                  />
                </div>
                  <ReactMarkdown>{result}</ReactMarkdown>
                </Message>
              </div>
            )}

            {result2 && result2 !== '' && result && result !== '' && (
              <div style={{flex: 1, padding: '0 10px'}}>
                <Message isUser={false}>
                <div className='flex flex-row items-center'>
                  {currentTool?.image_data?.example_url && (
                    <img 
                      src={currentTool.image_data.example_url} 
                      alt={`${currentTool.name} example 2`}
                      style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                    />
                  )}
                  <FontAwesomeIcon icon={faArrowRight} className="mr-2 ml-2 mb-2" />
                  <img 
                    src={servicesMap.find(service => service.id === 'perplexity').image_url} 
                    alt={currentTool.name} // Provide an appropriate alt attribute
                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginBottom: '8px' }} 
                    className='rotate-infinite'
                  />
                  </div>
                  <div className='flex flex-row justify-between items-center fade-colors-dark rounded text-center p-3 pb-4 mt-5 mb-5'>
                    <div className="loader h-10 w-10">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className='w-3/4'>Generating response from<br /> <strong>Perplexity AI</strong>...</div>
                  </div>
                </Message>
              </div>
            )}
          </div>
        ) : null
      }

      </MessagesArea>
      {showScrollButton && (
        <button onClick={scrollToBottom} className="relative mx-auto fixed bottom-20 p-2 bg-blue-500 w-1/2 text-white rounded-full shadow-lg">
            <FontAwesomeIcon icon={faArrowDown} />
        </button>
        )}
      <InputArea>
        <Input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message..."
        />
        <SendButton onClick={handleSend}>Send</SendButton>
      </InputArea>
    </ChatContainer>
  );
};

export default Chatbot;
