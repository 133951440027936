import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import config from "../config/config";
import { useNavigate, useLocation  } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setUser, reduceAICredits } from "../features/user/userSlice";
import { selectSession } from "../features/session/sessionSlice";
import { setThreadId, selectCurrentThreadId, updateThreadId, deleteThreadId } from "../features/threads/threadSlice";
import { selectCurrentTool } from "../features/currentTool/currentToolSlice";
import { openModal } from "../features/modals/jsonModalSlice";
import { selectCurrentProfile } from "../features/currentTool/currentProfileSlice";
import { generateChat, postMessage } from "../api/aiFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight, faCopy, faPaperPlane, faEdit, faAddressBook, faUpload, faBars, faAngleDoubleDown, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import socket from "../utils/socket";
import { copyToClipboard } from "../utils/commons";
import { addToast } from "../features/ui/uiSlice";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import markdownit from "markdown-it";
import hljs from "highlight.js";
import 'highlight.js/styles/monokai.css';
import markdownItMermaid from "markdown-it-mermaid";
import taskLists from "markdown-it-task-lists";
import mdVideos from "markdown-it-video";
//import miliMarkdown from "markdown-it-linkify-images";
import { full as emoji } from "markdown-it-emoji";
import { setCurrentTool } from "../features/currentTool/currentToolSlice";
import { faAddressCard, faFolderOpen, faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import SuggestedPrompts from "./SuggestedPrompts";
import BoardSelectorButton from "./BoardSelectorButton";
import { setCurrentThreadMessages } from "../features/threads/threadHistorySlice";
import ThreadHistoryCard from "./ThreadHistoryCard";
import NextActionOptions from "./NextActionOptions";
import VideoPlayer from "./VideoPlayer";
import SidebarProfilesCard from "./SidebarProfilesCard";

const ChatComponent = () => {
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL;
  const tools = useSelector((state) => [
    ...state.tools.items.tools,
    ...state.tools.items.assistants,
  ]);

  const user = useSelector(selectUser);
  const session = useSelector(selectSession);
  const currentTool = useSelector(selectCurrentTool);
  const textareaRef = useRef(null);

  const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const currentThreadId = useSelector(selectCurrentThreadId);
  const threadHistory = useSelector((state) => state.threadHistory.threads); // Access thread history from state
  const threadMessages = useSelector((state) => state.threadHistory.currentThreadMessages); // Access current thread messages from state
  const [messages, setMessages] = useState([]);
  const [messagesToSubmit, setMessagesToSubmit] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [submittedMessages, setSubmittedMessages] = useState([]);
  const [awaitingResponse, setAwaitingResponse] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [result, setResult] = useState("");
  const messagesEndRef = useRef(null);
  const [isTalking, setIsTalking] = useState(false);
  const [showOption, setShowOption] = useState("menu");
  const [showMobileSuggestedPrompts, setShowMobileSuggestedPrompts] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(true);

  useEffect(() => {
    if (tools.length > 0) {
      const specificTool = tools.find(
        (tool) => tool._id === config.defaultToolId
      );
      if (specificTool) {
        dispatch(setCurrentTool(specificTool));
      }
    }
  }, [tools]);

  useEffect(() => {
    if (currentTool && currentTool?.name) {
      setMessages([
        {
          id: 1,
          text: currentTool?.instructions
            ? currentTool?.instructions
            : `Hey! I'm ${currentTool?.name}. What's up?`,
          isUser: false,
        },
      ]);
    }
  }, [currentTool]);
  
  const toggleShowMobileSuggestedPrompts = () => {
    setShowMobileSuggestedPrompts(!showMobileSuggestedPrompts);
  };

  const handleCopy = async (text) => {
    const isCopied = await copyToClipboard(text);
    if (isCopied) {
      dispatch(addToast("Copied to clipboard!", "success"));
    } else {
      dispatch(addToast("Failed to copy!", "error"));
    }
  };

  const handleSend = async (text = inputValue) => {
    let finalInputValue = text;
    if (inputValue) {
      finalInputValue = inputValue;
    }
    if (!session) {
      dispatch(addToast("Please login to chat!", "error"));
      setIsAnalyzing(false);
      return;
    }
    if (user.ai_credits < 1) {
      dispatch(addToast("You need more AI credits to chat!", "error"));
      setIsAnalyzing(false);
      return;
    }
    if (currentTool.type === "assistant" && finalInputValue.trim() !== "") {
      setMessages((messages) => [
        ...messages,
        { id: messages.length + 1, text: finalInputValue, isUser: true },
      ]);
      setInputValue("");
      setIsAnalyzing(true);
      setAwaitingResponse(true);
      const postAssistant = await postMessage(
        user.dynamic_id,
        finalInputValue,
        currentThreadId,
        currentTool.oai_assistantId
      );
      setResult("");
      if (postAssistant) {
        const jsonData = JSON.parse(postAssistant.data);
        console.log('JSON Content:', jsonData);
        setMessages((messages) => [
          ...messages,
          {
            id: messages.length + 1,
            text: jsonData.content,
            isUser: false,
            service: "openai",
            messageCount: 1,
            next_action_options: jsonData.next_action_options,
          },
        ]);
        if (postAssistant?.tokens) {
          dispatch(reduceAICredits(postAssistant.tokens));
        }
        setAwaitingResponse(false);
        if (!currentThreadId) {
          dispatch(setThreadId(postAssistant.threadId));
        }
        scrollToBottom();
        setIsAnalyzing(false);
        //setDropdownLabel('Make it...');
        //setAwaitingResponse(true);
      }
    } else {
      console.log('Tool not recognized.');      
    };
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isAnalyzing, result]);

  const handleNewChat = () => {
    setMessages([
      {
        id: 1,
        text: currentTool?.instructions
          ? currentTool?.instructions
          : `Hey! I'm ${currentTool?.name}. What's up?`,
        isUser: false,
      },
    ]);
    setMessagesToSubmit([]);
    setSubmittedMessages([]);
    setAwaitingResponse(false);
    setResult("");
    dispatch(setThreadId(null));
    dispatch(setCurrentThreadMessages([]));
  };

useEffect(() => {
  if (currentThreadId) {
    const thread = threadHistory.find(thread => thread.oai_threadId === currentThreadId);
    if (thread) {
      dispatch(setCurrentThreadMessages(thread.messages));
    }
  }
}, [currentThreadId, threadHistory, dispatch]);

useEffect(() => {
  if (threadMessages?.length > 0) {
    const formattedMessages = threadMessages.map(msg => {
      let parsedContent;
      try {
        parsedContent = JSON.parse(msg.content);
      } catch (e) {
        parsedContent = null;
      }
      return {
        id: msg._id, // or a unique identifier
        text: parsedContent ? parsedContent.content : msg.content, // map content to text
        isUser: msg.role === 'user', // assuming you have this flag in your message object
        next_action_options: parsedContent ? parsedContent.next_action_options : null,
      };
    });
    setMessages(formattedMessages);
    scrollToBottom();
  }
}, [threadMessages]);


  useEffect(() => {
    const messageListener = (message) => {
      if (
        message.toolId &&
        message.dynamic_id &&
        message.dynamic_id === user.dynamic_id
      ) {
        let accumulator;

        if (message.chunkContent) {
          setIsAnalyzing(false);
          accumulator += message.chunkContent;
          setResult((prevResult) => prevResult + message.chunkContent);
          scrollToBottom();

          if (message === "--complete--") {
            accumulator = "";
          }
        }
      }
    };

    socket.on("chatStream", messageListener);

    return () => {
      socket.off("chatStream", messageListener);
    };
  }, [socket, user, currentTool]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey || e.ctrlKey) {
        e.preventDefault();
        // Insert a new line character
        const { selectionStart, selectionEnd } = e.target;
        const newValue =
          inputValue.substring(0, selectionStart) +
          "\n" +
          inputValue.substring(selectionEnd);
        setInputValue(newValue);
        // Move the cursor to the correct position after the newline
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
        }, 0);
      } else {
        e.preventDefault();
        handleSend();
      }
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputValue]);

  const handleAppendProfileContent = (content) => {
    setInputValue((prevValue) => prevValue + " " + content);
  };

  return (
    <>
      <div
        className="w-full flex flex-col rounded chat-return h-95vh">
        <div className="flex flex-col md:flex-row lg:gap-10 lg:ml-10">
          <div className="w-full h-full hidden lg:flex lg:w-1/4 lg:min-w-1/4 justify-center items-center p-3 pt-0 pb-0 h-80vh xl:h-85vh">
            <div className="flex flex-col min-w-96 gap-2 h-full justify-start mt-5 items-center">
              <div className="flex flex-row gap-2 justify-center items-center">
                <img
                  src={currentTool?.image_data.example_url}
                  alt="Avatar"
                  className={`hidden md:block border-2 border-primary rounded-full w-20 h-auto ${
                    isTalking ? "talking" : ""
                  }`}
                />
                <div className="text-center mb-0 md:mb-3 text-2xl font-bold text-poetsen text-primary">
                  {currentTool?.name}
                </div>
              </div>
              <div className="flex w-full grid-col-2 gap-2">
                {config.menu_options_chat_space && <button
                  onClick={() => setShowOption("menu")}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-700 w-full"
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>}
                {config.thread_history_chat_space && <button
                  onClick={() => setShowOption("history")}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-700 w-full"
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                </button>}
                {config.profiles && <button
                  onClick={() => setShowOption("profiles")}
                  className="bg-primary px-4 py-2 rounded hover:bg-gray-700 w-full"
                >
                  <FontAwesomeIcon icon={faAddressCard} />
                </button>}
              </div>
              {config.menu_options_chat_space && showOption && <div className="flex flex-row w-full hidden lg:block p-4 border border-primary mt-2 md:mt-0 overflow-auto rounded">
                {showOption === "menu" && (
                  <SuggestedPrompts setInputValue={setInputValue} handleSend={handleSend} />
                )}
                {config.thread_history_chat_space && showOption === "history" && (
                  <ThreadHistoryCard />
                )}
                {config.profiles && showOption === "profiles" && (
                  <SidebarProfilesCard
                    onProfileClick={handleAppendProfileContent}
                    header={'Click a profile to add it to your conversation.'}
                  />
                )}
              </div>}
            </div>
          </div>

          {/* Right column for displaying messages */}
          <div className="w-full lg:w-3/4 lg:min-w-3/4 mt-3 md:mt-0 p-4 pb-10 flex flex-col rounded bg-primary overflow-auto min-h-75vh h-75vh lg:h-80vh xl:h-85vh">
              <div className="hidden lg:flex lg:flex-col items-center p-2 rounded-lg shadow mb-2 text-primary bg-body">
                <VideoPlayer url={'https://otp.nyc3.cdn.digitaloceanspaces.com/GetStartedDesktop.mp4'} width={600} />
              </div>
              <div className={`lg:hidden flex flex-col items-center p-2 rounded-lg shadow mb-2 text-primary bg-body ${messages.length <= 2 ? 'mt-20' : 'mt-5'}`}>
                <img src='https://otp.nyc3.cdn.digitaloceanspaces.com/getStartedGIF.gif' alt='Get Started' className="w-fit rounded" style={{pointerEvents: "none"}} />
              </div>
            {messages.map((message, index) => (
              <div ref={messagesEndRef}
                key={message.id}
                className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 ${
                  message.isUser
                    ? "bg-primary border ml-10 lg:ml-56 text-poetsen text-lg"
                    : "text-primary bg-body markdown-content"
                }`}
              >
                <div className="flex flex-col w-11/12">
                  <ReactMarkdown
                    className="markdown-content"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >
                    {message.text}
                  </ReactMarkdown>
                  {message.next_action_options && !message.isUser && <NextActionOptions setInputValue={setInputValue} handleSend={handleSend} options={message.next_action_options} />}
                  {message.isUser && index > 0 && (
                    <div className="flex flex-row items-center w-full justify-end gap-1 mt-1">
                      <button
                        className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded"
                        onClick={() => handleCopy(message.text)}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                      <BoardSelectorButton
                        itemContent={message.text}
                        itemType={"prompt"}
                        userId={user._id}
                      />
                    </div>
                  )}

                  {!message.isUser && index > 0 && (
                    <div className="flex flex-row items-center w-full justify-end gap-1 mt-1">
                      <button
                        className="bg-primary hover:bg-gray-700 text-white px-3 py-1 rounded"
                        onClick={() => handleCopy(message.text)}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                      <BoardSelectorButton
                        itemContent={message.text}
                        itemType={"text"}
                        userId={user._id}
                      />
                    </div>
                  )}
                  
                </div>
              </div>
            ))}
            {isAnalyzing && (
              <div
                className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 text-primary bg-body shimmer`}
              >
                Analyzing
              </div>
            )}
            {result && (
              <div
                className={`flex flex-col items-center p-2 rounded-lg shadow mb-2 text-primary bg-body markdown-content`}
              >
                <div
                    className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 text-primary bg-body shimmer`}
                  >
                    Preparing your response...
                </div>
                <div className="flex flex-col w-11/12">
                  <ReactMarkdown
                    className="markdown-content"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >
                    {result}
                  </ReactMarkdown>
                  <div
                    className={`flex flex-col justify-center items-center p-2 rounded-lg shadow mb-2 text-primary bg-body shimmer`}
                  >
                    Preparing your response...
                  </div>
                </div>
              </div>
            )}
            
          </div>
        </div>

        <div className="bg-inverted pt-4 md:p-4 absolute bottom-2 w-full flex justify-end md:pr-6">
          
          <div className="flex flex-col lg:flex-row gap-2 items-end w-full lg:w-2/3 justify-end lg:mr-6">
            {showMobileSuggestedPrompts && <div className="flex flex-col w-11/12 lg:hidden p-4 h-96 bg-body border-2 border-gray-800 md:mt-0 overflow-auto rounded shadow-2xl slim-scrollbar">
                
                <SuggestedPrompts setInputValue={setInputValue} handleSend={handleSend} setShowMobileSuggestedPrompts={setShowMobileSuggestedPrompts} />
              
            </div>}

            <textarea
              ref={textareaRef}
              value={inputValue}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              rows={1}
              className="flex-grow min-h-12 p-2 rounded border border-gray-500 text-black bg-gray-100 resize-none overflow-hidden w-full lg:w-fit"
              style={{ maxHeight: "250px" }} // Adjust the max height as needed
            />
            <div className="flex flex-row gap-2 items-center w-full lg:w-fit justify-center items-center px-4 lg:px-0">
              <button className="bg-primary p-2 lg:hidden rounded hover:bg-gray-500 w-1/5 lg:w-fit" onClick={toggleShowMobileSuggestedPrompts}>
                <FontAwesomeIcon icon={showMobileSuggestedPrompts ? faAngleDoubleDown : faBars} className="lg:mr-2" />
                <span className="hidden lg:block">Menu</span>
              </button>
              {config.profiles && <button
                  onClick={() => setShowOption("profiles")}
                  className="lg:hidden bg-primary px-4 py-2 rounded hover:bg-gray-700 w-1/5 lg:w-fit"
                >
                  <FontAwesomeIcon icon={faAddressCard} />
                </button>}
              <button className="bg-primary p-2 rounded hover:bg-gray-500 w-1/5 lg:w-fit lg:flex lg:justify-center lg:items-center" disabled={messages.length <= 1} onClick={handleNewChat}>
                <FontAwesomeIcon icon={faArrowRotateRight} className="lg:mr-2" />
                <span className="hidden lg:flex">New Chat</span>
              </button>
              <button
                onClick={handleSend}
                className="bg-primary p-2 rounded hover:bg-gray-500 flex flex-grow justify-center items-center lg:block lg:w-fit"
              >
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                SEND IT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatComponent;
