// login.jsx
import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../features/user/userSlice';
import { authUser } from '../api/userFunctions';
import { StytchLogin } from '@stytch/react';
import { setSession, deleteSession } from '../features/session/sessionSlice';
import config from '../config/config';
import Cookies from 'js-cookie';
const sitename = config.siteName;
const logo = config.favicon;
const stytch_login_url = process.env.REACT_APP_STYTCH_LOGIN_URL;


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const stytch_session = Cookies.get('stytch_session');
    
    const autoAuthenticate = async () => {
      if (stytch_session) {
        const user = await authUser(stytch_session);
        if (user?.success) {
          dispatch(setUser(user?.data));
          dispatch(setSession({ stytch_session_token: stytch_session }));
          navigate('/');
        } else {
          dispatch(setUser(null));
          dispatch(deleteSession());
          Cookies.remove('stytch_session');
          Cookies.remove('stytch_session_jwt');
          //navigate('/login');
        }
      }
    };

    autoAuthenticate();
  }, [dispatch, navigate]);

  const stytch_config = {
  "products": [
    "oauth",
    "emailMagicLinks"
  ],
  "oauthOptions": {
    "providers": [
      {
        "type": "google"
      }
    ],
    "loginRedirectURL": stytch_login_url,
    "signupRedirectURL": stytch_login_url
  },
  "emailMagicLinksOptions": {
    "loginRedirectURL": stytch_login_url,
    "loginExpirationMinutes": 30,
    "signupRedirectURL": stytch_login_url,
    "signupExpirationMinutes": 30,
    "loginTemplateId": config.loginTemplateId,
    "signupTemplateId": config.signupTemplateId
  }
};
  const styles = {
  "container": {
    "backgroundColor": "#EED7F1",
    "borderColor": "#9c73fc",
    "borderRadius": "8px",
    "width": "360px"
  },
  "colors": {
    "primary": "#4B0082",
    "secondary": "#5C727D",
    "success": "#0C5A56",
    "error": "#8B1214"
  },
  "buttons": {
    "primary": {
      "backgroundColor": "#4B0082",
      "textColor": "#EED7F1",
      "borderColor": "#19303D",
      "borderRadius": "4px"
    },
    "secondary": {
      "backgroundColor": "#FFFFFF",
      "textColor": "#19303D",
      "borderColor": "#19303D",
      "borderRadius": "4px"
    }
  },
  "inputs": {
    "backgroundColor": "#FFFFFF00",
    "borderColor": "#19303D",
    "borderRadius": "4px",
    "placeholderColor": "#8296A1",
    "textColor": "#4B0082"
  },
  "fontFamily": "Arial",
  "hideHeaderText": false,
  "logo": {
    "logoImageUrl": logo,
  }
}

  return (<>
    <div className='w-full md:w-1/3 mx-auto px-5 flex flex-col justify-center items-center pb-10'>
        
      <span className='mt-3 mb-2 text-poetsen text-primary text-2xl lg:text-4xl'>{sitename}</span>
      <img src={logo} alt='Logo' className='w-16 h-16 lg:w-24 lg:h-24 rounded-full border-2 border-primary' />
        <h1 className="mt-2 md:mt-3 text-center text-3xl text-poetsen mb-2">Login or Sign Up</h1>
        <a href='https://gaim.ai' alt='GAIM.AI' target='_blank' rel='noreferrer noopener' className="">
        <div className='flex flex-row items-center justify-center mb-3 mt-2 md:mt-3 bg-black rounded-full p-2 px-4 border-2 border-primary'>
          
          
                <img
                    src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                    alt="Avatar"
                    className={`w-6 h-6`}
                    /> 
                    <div className='text-center text-white ml-2' style={{color: '#99abd6'}}>Powered By GAIM.AI</div>
            
        </div>
        </a>
        <p className='rounded bg-primary px-3 py-2 mb-3 text-white border-4 border-gray-900'>If you choose to log in or sign up via an email address, you will instantly receive an email that contains a link. Click this link in your email to complete the login or sign up process.</p>
        <StytchLogin config={stytch_config} styles={styles} />
        <p className='text-center mt-6 text-sm'>*We will never sell your data or spam you.</p>
    </div>
    
    
    
    
    </>);
}

export default Login;