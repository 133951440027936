import axios from 'axios';
import config from '../config/config';
import Cookies from 'js-cookie';

const apiURL = process.env.REACT_APP_API_URL;

//NFTs
export const getAllWalletNFTs = async (walletAddress, chainId) => {
  console.log('walletAddress session:', walletAddress)
  try {
    const response = await axios.get(`${apiURL}/crypto/nfts/getAllWalletNFTs/${walletAddress}/${chainId}`, 
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //'stytch_session_token': session.stytch_session_token,
      },
      withCredentials: true
    });
    console.log('getAllWalletNFTs response:', response.data);
    return response.data;
  } catch (error) {
      //console.error('Error authenticating user', error);
  }
};

export const getUserByDynamicID = async (dynamic_id) => {
    try {
      const response = await axios.get(`${apiURL}/user/${dynamic_id}/${config.app_id}`,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'stytch_session_token': Constants['stytch_session_token'], //replace with hook for session token
        },
        //withCredentials: true
      });
      return response.data;
    } catch (error) {
        //console.error('Error fetching user by dynamic id', error);
    }
  };

export const authUser = async (session_token) => {
  try {
    const response = await axios.get(`${apiURL}/auth/validateSessionTokenReturnUser/${session_token}/${config.app_id}/${config.mailerLiteGroupID}`, 
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //'stytch_session_token': session.stytch_session_token,
      },
      withCredentials: true
    });
    return response.data;
  } catch (error) {
      //console.error('Error authenticating user', error);
  }
};

  //update user profile
  export const updateUserProfile = async (dynamic_id, payload, session) => {
    try {
      const response = await axios.put(`${apiURL}/user/${dynamic_id}/${config.app_id}`, payload,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      return response.data;
    } catch (error) {
        //console.error('Error updating user profile', error);
    }
  };

  //delete user profile
  export const deleteUserProfile = async (dynamic_id, session) => {
    try {
      const response = await axios.delete(`${apiURL}/user/${dynamic_id}/${config.app_id}`,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      return response.data;
    } catch (error) {
        //console.error('Error deleting user profile', error);
    }
  };

  export const updateBYOKkey = async (payload, session) => {
    try {
      const response = await axios.post(`${apiURL}/user/updateBYOKkey`, payload,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      console.log('updated user: ', response.data);
      return response.data;
    } catch (error) {
        //console.error('Error updating user profile', error);
    }
  };

  export async function addProfile(dynamic_id, profileData, session) {
    try {
      const response = await axios.post(`${apiURL}/user/${dynamic_id}/profiles`, profileData,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      console.log('addProfile: ', response.data);
      // Handle success
    } catch (error) {
      console.error('Error adding profile:', error);
      // Handle errors
    }
  }
  
  export async function deleteProfile(dynamic_id, profileId, session) {
    try {
      const response = await axios.delete(`${apiURL}/user/${dynamic_id}/profiles/${profileId}`, {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      console.log('deleteProfile: ', response.data);
      // Handle success
    } catch (error) {
      console.error('Error deleting profile:', error);
      // Handle errors
    }
  }

  export async function modifyProfile(dynamic_id, profileId, profileData, session) {
    try {
      const response = await axios.put(`${apiURL}/user/${dynamic_id}/profiles/${profileId}`, profileData, {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      console.log('modifyProfile: ', response.data);
      // Handle success
    } catch (error) {
      console.error('Error modifying profile:', error);
      // Handle errors
    }
  }
  
  export async function fullLogout(session) {
    try {
      const response = await axios.get(`${apiURL}/auth/logout/${session.stytch_session_token}`, {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'stytch_session_token': session.stytch_session_token,
        },
        //withCredentials: true
      });
      Cookies.remove('stytch_session');
      Cookies.remove('stytch_session_jwt');
      // Handle success
    } catch (error) {
      console.error('Error logging out:', error);
      // Handle errors
    }
  }

  /*export const get_user = async (dynamic_id) => {
    try {
      if (dynamic_id === undefined || dynamic_id === null || dynamic_id === '' || dynamic_id === 'user' || dynamic_id === 'undefined') {
        return null;
      }
      const response = await axios.get(`${apiURL}/crypto/get-user/${dynamic_id}`,
      {
        headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'stytch_session_token': Constants['stytch_session_token'], //replace with hook for session token
        },
        //withCredentials: true
      });
      console.log('get_user response:', response.data.user)
      return response.data.user;
    } catch (error) {
        //console.error('Error fetching user by dynamic id', error);
    }
  };*/

/*export const getNFTMetadata = async (contractAddress, tokenId, chainId) => {
  try {
    let endpoint;
    if (chainId === 1) {
      endpoint = 'getNFTMetadataEth';
    } else if (chainId === 137) {
      endpoint = 'getNFTMetadataPolygon';
    } else if (chainId === 8453) {
      endpoint = 'getNFTMetadataBase';
    }
    const response = await axios.get(`${apiURL}/crypto/nfts/${endpoint}/${contractAddress}/${tokenId}/${chainId}`,
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      //'stytch_session_token': Constants['stytch_session_token'], //replace with hook for session token
      },
      //withCredentials: true
    });
    console.log('getNFT response:', response.data);
    return response.data;
  } catch (error) {
      //console.error('Error fetching NFT', error);
  }
};*/