import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon  } from '@fortawesome/free-solid-svg-icons';
import { setCurrentTheme } from '../features/currentTool/currentThemeSlice';
import { useDispatch } from 'react-redux';

const ThemeSwitcher = () => {
  const dispatch = useDispatch();
  // Initialize theme state with value from localStorage if available
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'dark'; // Default to 'light' theme if none is stored
  });

  // Effect to apply the theme class to the document and save the current theme to localStorage
  useEffect(() => {
    document.documentElement.classList.remove('theme-light', 'theme-dark');
    document.documentElement.classList.add(`theme-${theme}`);
    dispatch(setCurrentTheme(theme)); // Update the current theme in Redux store
    localStorage.setItem('theme', theme); // Save current theme to localStorage
  }, [theme]); // Re-run the effect when theme changes

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <button onClick={toggleTheme} className="flex items-center">
      <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} className="text-lg xl:mr-3" />
      {/*<span className="ml-2">{theme === 'light' ? 'Dark' : 'Light'} Mode</span>*/}
    </button>
  );
};

export default ThemeSwitcher;

