import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faGlobe, faMicrochip, faPaperPlane, faX, faImages, faDollarSign, faStore, faRobot, faFire, faCloudArrowUp, faTachometer, faMessage, faPaperclip, faAddressCard, faEnvelope, faShop, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openModalIFrame } from '../features/modals/modaliframeSlice';
import { openModal } from '../features/packages/modalPackagesSlice';
import config from '../config/config';
import { faDiscord, faFacebook, faInstagram, faLinkedin, faMedium, faPinterest, faTelegram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const siteName = config.siteName;
const siteLogo = config.logoUrl;

const Footer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
  return (<>
    <div className="flex w-full flex-col justify-center md:flex-row md:justify-between items-center mt-20 pt-0 pb-4 border-t border-primary bg-black-main text-white rounded">
      <div className="p-4 w-full md:w-1/5 flex flex-col text-poetsen">
        {/*<Link to='https://www.dextools.io/app/en/base/pair-explorer/0xff5375bd65056dbe6119256fc3be2eb0ffa8a840?t=1712845998905' alt='Chart for Andy on Base' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faChartLine} className='mr-2' />Andy's Chart
        </Link>
        <Link to='https://t.me/AndyOnBasePortal' alt='Telegram Group for Andy on Base' target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faPaperPlane} className='mr-2' />Andy's Telegram Group
        </Link>*/}
        {config.twitter && <Link to={`https://x.com/${config.twitter}`} alt={`${siteName} on X Twitter`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faXTwitter} className='mr-2' />{siteName} On X (Twitter)
        </Link>}
        {config.facebook && <Link to={`https://facebook.com/${config.facebook}`} alt={`${siteName} on Facebook`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faFacebook} className='mr-2' />{siteName} on Facebook
        </Link>}
        {config.instagram && <Link to={`https://instagram.com/${config.instagram}`} alt={`${siteName} on Instagram`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faInstagram} className='mr-2' />{siteName} on Instagram
        </Link>}
        {config.pinterest && <Link to={`https://pinterest.com/${config.pinterest}`} alt={`${siteName} on Pinterest`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faPinterest} className='mr-2' />{siteName} on Pinterest
        </Link>}
        {config.linkedin && <Link to={`${config.linkedin}`} alt={`${siteName} on LinkedIn`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faLinkedin} className='mr-2' />{siteName} on LinkedIn
        </Link>}
        {config.youtube && <Link to={`https://youtube.com/${config.youtube}`} alt={`${siteName} on YouTube`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faYoutube} className='mr-2' />{siteName} On YouTube
        </Link>}
        {config.telegram && <Link to={`https://facebook.com/${config.telegram}`} alt={`${siteName} on Telegram`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faTelegram} className='mr-2' />{siteName} on Telegram
        </Link>}
        {config.discord && <Link to={`${config.discord}`} alt={`${siteName} on Discord`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faDiscord} className='mr-2' />{siteName} on Discord
        </Link>}
        {config.tiktok && <Link to={`https://tiktok.com/${config.tiktok}`} alt={`${siteName} on TikTok`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faTiktok} className='mr-2' />{siteName} on TikTok
        </Link>}
        {config.medium && <Link to={`https://medium.com/@${config.medium}`} alt={`${siteName} on Medium`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faMedium} className='mr-2' />{siteName} on Medium
        </Link>}
        {config.website_1 && config.website_1_title && <Link to={`${config.website_1}`} alt={`${config.website_1_title}`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faGlobe} className='mr-2' />{config.website_1_title}
        </Link>}
        {config.website_2 && config.website_2_title && <Link to={`${config.website_2}`} alt={`${config.website_2_title}`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faGlobe} className='mr-2' />{config.website_2_title}
        </Link>}
        {config.website_3 && config.website_3_title && <Link to={`${config.website_3}`} alt={`${config.website_3_title}`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faGlobe} className='mr-2' />{config.website_3_title}
        </Link>}
        {config.email && <Link to={`mailto://${config.email}`} alt={`Email ${siteName}`} target='_blank' rel='noreferrer noopener' className="block w-full text-left px-5 py-3 hover:text-gray-200 hover:bg-gray-700 focus:outline-none">
          <FontAwesomeIcon icon={faEnvelope} className='mr-2' />Email {siteName}
        </Link>}
      </div>
      <div className="p-4 w-full md:w-3/5 flex flex-col justify-center text-poetsen">
        <div className='md:flex flex-col items-center justify-center hidden'>
            <img
                    src={siteLogo}
                    alt="One Trick Pony AI"
                    className={`w-24 h-auto border-2 border-primary rounded-full`}
                /> 
            <span className='text-5xl text-primary font-bold hidden md:block'>{siteName}</span>
        </div>
        <div className='mt-8' style={{color: '#99abd6'}}>
            <a href='https://gaim.ai' alt='GAIM.AI' target='_blank' rel='noreferrer noopener' className="flex flex-col text-center items-center">
                <img
                    src="https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png"
                    alt="Powered by GAIM.AI"
                    className={`w-8 h-8`}
                    /> 
                    Powered by GAIM.AI
            </a>
        </div>
      </div>
      <div className="p-4 w-full md:w-1/5 flex flex-col text-poetsen text-lg">
            <Link to='/dashboard' className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faTachometer} className='mr-2' />Dashboard
            </Link>
            {config.chat_space && <Link to='/' className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faCommentDots} className='mr-2' />{config.chat_space_title || 'Chat Space'}
            </Link>}
            {config.boards && <Link to='/boards' className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faPaperclip} className='mr-2' />{config.boards_title || 'Boards'}
            </Link>}
            {config.shopUrl && config.shopUrl_title && <Link to={config.shopUrl} alt={config.shopUrl_title} target='_blank' rel='noreferrer noopener' className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faShop} className='mr-2' />{config.shopUrl_title || 'Shop'}
            </Link>}
            <Link to='/packages' className="mt-2 block w-full text-left px-5 py-3 bg-primary hover:text-gray-200 hover:bg-gray-700 focus:outline-none rounded">
                <FontAwesomeIcon icon={faAddressCard} className='mr-2' />Upgrade Plan
            </Link>
            
      </div>
      
    </div>
    <div className='w-full flex flex-col items-center justify-center text-poetsen my-4'>
    Copyright &copy; {new Date().getFullYear()} {siteName}. All Rights Reserved.
  </div>
  </>);
};

export default Footer;