import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentTool, selectCurrentTool } from '../features/currentTool/currentToolSlice';
import { close } from '../features/mobile/menuSlice';
import { setThreadId } from '../features/threads/threadSlice';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTachometerAlt, faGear, faMessage, } from '@fortawesome/free-solid-svg-icons';
import { fullLogout } from '../api/userFunctions';
import { selectSession, deleteSession } from '../features/session/sessionSlice';
import { logout } from '../features/user/userSlice';
import { faFaceFrown } from '@fortawesome/free-regular-svg-icons';

const DashboardThreadHistoryCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session = useSelector(selectSession);
  const user = useSelector(state => state.user.user);
  const threads = useSelector(state => state.threadHistory.threads);
  const tools = useSelector((state) => [
    ...(state.tools.items.tools ?? []), // Use optional chaining or default to empty array
    ...(state.tools.items.assistants ?? [])
  ].sort((a, b) => a.name.localeCompare(b.name)));
  
  const currentTool = useSelector(selectCurrentTool);
  const loading = useSelector((state) => state.tools.status) === 'loading';

  const handleLogout = async () => {
    const loggingOut = await fullLogout(session);
    dispatch(logout());
    dispatch(deleteSession());
    navigate('/login');
};

const truncateContent = (content, maxLength = 100) => {
  if (!content) return 'No content available.';
  return content.length > maxLength ? content.slice(0, maxLength) + '...' : content;
};



  return (
    <div className='overflow-auto h-96 max-h-96 slim-scrollbar w-full'>
    <div className="w-full rounded shadow-lg p-5 bg-primary flex flex-col gap-2 justify-center">
      <div className="font-bold text-3xl text-poetsen mx-auto mb-6 flex justify-center items-center">
        Recent Threads
      </div>
      {/* Navigation Items */}
      <div className="flex flex-col gap-2 mt-2 lg:mt-5">
        {threads?.length > 0 ? (threads?.slice(0, 15).map((thread) => (
            <div key={thread._id} className='flex border border-gray-300 flex-col gap-1 rounded px-2 pt-1 hover:text-gray-800 hover:border-gray-800 cursor-pointer hover:bg-gray-200' onClick={() => {dispatch(setThreadId(thread.oai_threadId)); dispatch(close()); dispatch(setCurrentTool(tools.find(tool => tool.oai_assistantId === thread.oai_assistantId))); navigate('/');}}>
              <div className='flex items-center justify-start text-poetsen'>
                <FontAwesomeIcon icon={faMessage} className='mr-2 text-inverted' />{truncateContent(thread.messages[0].content, 100)}
              </div>
              <div className='flex items-center justify-end text-gray-900'>
                {/* <img src={tools.find(tool => tool.oai_assistantId === thread.oai_assistantId)?.image_data.example_url} alt="Tool" className="w-6 h-6 rounded-full" /> */}
                {new Date(thread.lastUpdated).toLocaleString('en-US')}
                
              </div>
            </div>
          ))) : (<>
            <div className='text-poetsen text-lg text-center mb-4 flex flex-row justify-center items-center'><FontAwesomeIcon icon={faFaceFrown} className='mr-2 text-2xl' />No recent threads found.</div>
          </>)}
      </div>
    </div>
    </div>
  );
};

export default DashboardThreadHistoryCard;
