// src/components/DashboardCard.js
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AddBoardItemForm from './AddBoardItemForm';
import config from '../config/config';
import { createBoard } from '../features/boards/boardSlice';
import { selectUser } from '../features/user/userSlice';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DashAddBoardItem = () => {
    const dispatch = useDispatch();
    const boards = useSelector(state => state.boards.boards); // Get boards from the store
    const [selectedBoardId, setSelectedBoardId] = useState('');
    const user = useSelector(selectUser);
    const [newBoardName, setNewBoardName] = useState('');

    useEffect(() => {
        if (boards.length > 0) {
            setSelectedBoardId(boards[0]._id); // Set the first board as the default selected board
        }
    }, [boards]);

    const handleBoardChange = (event) => {
        setSelectedBoardId(event.target.value);
    };

    const handleCreateBoard = async () => {
        if (newBoardName.trim()) {
            await dispatch(createBoard({ name: newBoardName, userId: user._id })).unwrap();
            setNewBoardName('');
        }
    };

    const selectedBoard = boards.find(board => board._id === selectedBoardId);

    return (
        <div className="bg-primary rounded p-4 shadow-lg mb-4 h-96 min-h-96">
            <h2 className="text-xl font-bold mb-4 text-poetsen">Add New {config.boards_title_singular} Item</h2>
            {boards?.length > 0 ? (<div className="mb-4">
                <label htmlFor="board" className="block text-sm font-medium">Select {config.boards_title_singular}</label>
                <select
                    value={selectedBoardId}
                    onChange={handleBoardChange}
                    className="border rounded px-4 py-2 w-full mb-2 text-black"
                >
                    {boards.map(board => (
                        <option key={board._id} value={board._id}>{board.name}</option>
                    ))}
                </select>
            </div>) : (<>
            <div className='flex flex-col items-center justify-center mt-5 md:mt-20 bg-primary w-full p-5 rounded gap-5'>
                <div className="text-poetsen text-xl text-inverted text-center">No {config.boards_title} found.<br />Please create a new {config.boards_title_singular} first.</div>
                <div className="flex flex-row justify-center items-center ">
                    <input
                        type="text"
                        placeholder={`New ${config.boards_title_singular} Name`}
                        value={newBoardName}
                        onChange={(e) => setNewBoardName(e.target.value)}
                        className="border rounded px-4 py-2 mr-2 text-black"
                    />
                    <button
                        onClick={handleCreateBoard}
                        className="flex justify-center items-center text-poetsen uppercase text-primary hover:text-white font-bold bg-body p-1 px-3 mx-2 hover:bg-gray-500 rounded"
                    >
                        <FontAwesomeIcon icon={faPlusCircle} className='mr-2 py-2'/>Create {config.boards_title_singular}
                    </button>
                </div>
            </div>
            </>)}
            {selectedBoardId && (
                <AddBoardItemForm boardId={selectedBoardId} onFormToggle={() => {}} />
            )}
        </div>
    );
};

export default DashAddBoardItem;
