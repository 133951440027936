import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from '../features/user/userSlice';
import { selectPackages } from '../features/packages/packageSlice';
import config from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CurrentSubscriptionCard = () => {
  const packages = useSelector(selectPackages);
  const user = useSelector(selectUser);
  const [currentPackage, setCurrentPackage] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (packages.length > 0) {
      const currentPackage = packages.find(
        (packagex) => packagex._id === user.subscription_id
      );
      if (currentPackage) {
        setCurrentPackage(currentPackage);
      }
    }
  }, [packages]);

  return (
    <div className="w-full rounded  h-fit lg:h-96 lg:max-h-96 overflow-auto slim-scrollbar shadow-lg p-5 bg-primary flex flex-col gap-2 justify-between">
      <div className="flex flex-col lg:flex-row justify-between items-center px-4">
        <img src={currentPackage?.image_url} alt="Current Subscription" className={`w-28 h-auto rounded-full`} />
        <div className="flex flex-col gap-2 w-full justify-center items-center">
          <div className="font-bold text-3xl text-poetsen">
            Current Plan
          </div>
          <div className="text-xl text-poetsen text-center font-bold flex flex-row gap-2 justify-center items-center px-4 py-2 border-2 border-green-400 rounded"><FontAwesomeIcon icon={faArrowRight} />{currentPackage?.name}</div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full justify-center items-center">
        <div className="text-lg text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='text-green-400 mr-2' />Monthly Credits: {currentPackage?.ai_credits}</div>
        <div className="text-lg text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='text-green-400 mr-2' />Monthly Words: {currentPackage?.ai_credits * 5}</div>
        <div className="text-lg text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='text-green-400 mr-2' />Premium Responses: Yes</div>
        <div className="text-lg text-poetsen"><FontAwesomeIcon icon={faPlusCircle} className='text-green-400 mr-2' />Rollover Credits: Yes</div>
      </div>
      <div>
        <button onClick={()=>navigate('/packages')} className='flex flex-row gap-2 w-full justify-center items-center px-4 py-2 border-2 border-green-400 rounded hover:bg-green-400 text-white'>
          <FontAwesomeIcon icon={faPlusCircle} className='mr-2' />Update Plan
        </button>
      </div>
      
    </div>
  );
};

export default CurrentSubscriptionCard;
